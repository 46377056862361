<ngx-spinner [fullScreen]="true" type="ball-scale-multiple" name="gp-model-spinner"></ngx-spinner>
<div class="container">
  <app-glue-plan-form
   [data]="data"
   (onSaveGlueplan)="onSaveGlueplan($event)">
  </app-glue-plan-form>
<kendo-dialog-actions kendo-dialog-action class="clsaction">
  <button kendoButton themeColor="error" (click)="closeDialog()" class="float-right" translate>
    {{'App.Close' | translate}}
  </button>
  <div style="flex-grow: 1;"></div>
  <button kendoButton type="submit" themeColor="primary" (click)="onSave()" [disabled]="!childComponent?.gluingPlanForm?.valid" translate>
    {{ data.isNew ? ('App.Add'  | translate) : ('App.Update' | translate)}}
  </button>
</kendo-dialog-actions>
</div>
