<kendo-appbar themeColor="primary">

  <div class="custom-toolbar">
    <button *ngIf="!locked"
        kendoButton
        [svgIcon]="menuSvg"
        fillMode="flat"
        (click)="toggleExpanded()"
    ></button>
  </div>

  <kendo-appbar-section>
    <a class="toolbar-logo">
      <img alt="Optinex logo" [attr.src]="'assets/img/fx_logo.png'">
    </a>
  </kendo-appbar-section>

  <kendo-appbar-spacer>
  </kendo-appbar-spacer>

  <kendo-appbar-section>
    {{ configuration.environment }}
  </kendo-appbar-section>

  <kendo-appbar-spacer>
  </kendo-appbar-spacer>

  <kendo-appbar-section>
    <button kendoButton fillMode="flat" themeColor="base" *ngIf="loggedIn" (click)="logout()">
      <kendo-svgicon [icon]="logoutIcon"></kendo-svgicon>
    </button>
  </kendo-appbar-section>
</kendo-appbar>
  <div kendoDialogContainer></div>
  <kendo-drawer-container>
      <kendo-drawer
          #drawer
          [width]="300"
          [items]="authenicatedNodes"
          [mode]="mode"
          [mini]="mini"
          [expanded]="expanded"
          (select)="onSelect($event)"
          [autoCollapse]="autoCollapse"
          [isItemExpanded]="isItemExpanded">
          <ng-template kendoDrawerHeaderTemplate *ngIf="expanded">
            <app-user-settings [ngClass]="{'fixed-drawer': locked, 'unfix-drawer': !locked}"
              [locked]="locked"
              [expanded]="expanded"
              (lockedMenu)="confLockState($event)"
              (toggleExpanded)="drawer.toggle()"
              *ngIf="loggedIn && expanded">
            </app-user-settings>
          </ng-template>
          <ng-template kendoDrawerItemTemplate let-item>
            <kendo-svgicon [icon]="item.svgIcon"></kendo-svgicon>
            <div *ngIf="expanded" translate>{{ item.text }}</div>
          </ng-template>
      </kendo-drawer>

      <kendo-drawer-content>
        <router-outlet></router-outlet>
      </kendo-drawer-content>
  </kendo-drawer-container>
