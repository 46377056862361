import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { lastValueFrom } from 'rxjs';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-machine-selector-dropdown',
  template: `
  <kendo-formfield appearance="fill"  [class]="widthClass">
    <kendo-label *ngIf="showLabel">{{"Operative.Machine" | translate}}</kendo-label>
    <kendo-dropdownlist
     [defaultItem]="defaultItem"
     [value]="selectedMachineId"
     [data]="groupMachines"
     [readonly]="readonly"
     textField="name"
     valueField="machineId"
     [valuePrimitive]="true"
     [disabled]="disableControl"
     (selectionChange)="onChangedVal($event)">
    </kendo-dropdownlist>
  </kendo-formfield>`,
  styleUrls: ['./machine-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MachineSelectorComponent,
      multi: true
    }
  ]
})
export class MachineSelectorComponent implements OnInit, ControlValueAccessor {
  machines: IMachineDTO[];
  groupMachines: GroupResult[];
  selectedMachineId:number = null;
  @Input() showLabel: boolean = true;
  @Input() set machineGroupId(value: number) {
    this.machineGroupId = value;
    if (value) {
      this.loadMachines();
    }
  };

  @Input() set machineId(value: number) {
    if(value > 0){
      this.selectedMachineId = value;
    }
  };

  @Input() readonly: boolean = false;
  disableControl: boolean = false;
  @Input() widthClass: string = 'min-width-dropdown';
  @Input() isStaticalPressGroup: boolean | null;
  @Input() isContinuousPressGroup: boolean | null;

  @Output() selectedMachine : EventEmitter<IMachineDTO> = new EventEmitter<IMachineDTO>();

  private onTouched!: Function;
  private onChanged!: Function;

  public defaultItem: { name: string; valueField: number } = {
    name: 'App.SelectItem',
    valueField: null
  };

  constructor(private machinService: MachineService, public translateService: TranslateService) { }

  async ngOnInit() {
    await this.loadMachines();
    this.translateService.get(this.defaultItem.name).subscribe((res: string) => {
      this.defaultItem.name = res;
    });
  }

  async loadMachines() {
    this.machines = [];

    const result = await lastValueFrom(
      this.machinService.query(this.machineGroupId, this.isStaticalPressGroup, this.isContinuousPressGroup)
    );

    this.machines = result.data;

    this.groupMachines = groupBy(this.machines, [
      { field: 'siteCode' }
    ]) as GroupResult[];

    this.emitMachine();
  }

  private emitMachine() {
    if(this.selectedMachineId) {
      this.selectedMachine.emit(this.machines.find(x => x.machineId === this.selectedMachineId));
    }
  }

  onChangedVal(machineData:IMachineDTO) {
    this.onTouched(this.selectedMachineId);
    this.selectedMachineId = machineData?.machineId;
    this.selectedMachine.emit(machineData);
    this.onChanged(this.selectedMachineId);
  }

  writeValue(machineId: number) {
    this.selectedMachineId = machineId;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this.disableControl = isDisabled;
  }
}
