import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerComponent, DrawerItem, DrawerMode, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import {
  SVGIcon,
  menuIcon,
  starOutlineIcon,
  logoutIcon
} from '@progress/kendo-svg-icons';
import { UserService } from './core/services/http-services/common/user.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environment/environment';
import { Title } from '@angular/platform-browser';
import { AuthService } from './core/services/auth-service/auth.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Location } from '@angular/common';

marker('AppComponent.Admin');
marker('AppComponent.Admin_Entity');
marker('AppComponent.Admin_EntityActivity');
marker('AppComponent.Admin_EntityResource');
marker('AppComponent.Admin_EntityGroup');
marker('AppComponent.Admin_EntityProperty');
marker('AppComponent.Admin_Model');
marker('AppComponent.Analytics');
marker('AppComponent.Beams');
marker('AppComponent.General_Dashbord');
marker('AppComponent.Glue_Plans');
marker('AppComponent.Gluing_Plan');
marker('AppComponent.Glulam_Operator');
marker('AppComponent.Glulam_Planning');
marker('AppComponent.Glulam_Planning_Statical');
marker('AppComponent.Glulam_Scheduler_Statical');
marker('AppComponent.Log_Class');
marker('AppComponent.Models');
marker('AppComponent.Operational_Dashbord');
marker('AppComponent.Optimization');
marker('AppComponent.Pattern_Analysis');
marker('AppComponent.Production_Order');
marker('AppComponent.Production_Order_Schedule');
marker('AppComponent.Production_Order_Gantt');
marker('AppComponent.Reports');
marker('AppComponent.Saw_Pattern');
marker('AppComponent.Settings');
marker('AppComponent.Tactical_Dashbord');
marker('AppComponent.Availability');
marker('AppComponent.AoT_Balance');
marker('AppComponent.AoT_Balance_Report');
marker('AppComponent.Glulam');
marker('AppComponent.Glulam');
marker('AppComponent.Machine');
marker('AppComponent.Operative');
marker('AppComponent.Model');
marker('AppComponent.Stock');
marker('AppComponent.Stock_Location');
marker('AppComponent.Stock_Trans_Type');
marker('AppComponent.CLT');
marker('AppComponent.Plan');
marker('AppComponent.MachineGroup');
marker('AppComponent.Handeling_Unit_Report');
marker('AppComponent.Schedule');
marker('AppComponent.Shift');
marker('AppComponent.Deviation');
marker('AppComponent.Demand_Report');
marker('AppComponent.Device');
marker('AppComponent.OptimizeSequencer');
marker('AppComponent.LogForecast');
marker('AppComponent.Production_Order_ScheduleNg');
marker('AppComponent.OptimizeRuns');
marker('AppComponent.Production_Order_Calendar');
marker('AppComponent.Instruction');
marker('AppComponent.InstructionLine');
marker('AppComponent.Parts');
marker('AppComponent.Part');
marker('AppComponent.Profile');
marker('AppComponent.ShapeList');
marker('AppComponent.EditMotherBoard');
marker('AppComponent.EditProfileView');
marker('AppComponent.PlanView');
marker('AppComponent.EditPlan');
marker('AppComponent.Tactical');

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  styles: [
    `
      html,
      body,
      app-root {
        padding: 0;
        height: 100%;
      }

      app-root {
        display: flex;
        flex-direction: column;
      }

      kendo-drawer-container {
        flex: 1 1 auto;
        overflow-y: auto;
        display: flex;
      }

      kendo-drawer-content {
        flex: 1 1 auto;
        overflow-y: scroll;
        overflow-x: scroll;
        margin-botom: 0px;
        height: calc(100% - 30px)
      }

      .toolbar-logo img {
        height: 30px;
      }

      .main-content-margin{
        margin: 10px;
      }
    `
  ],
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public mode:DrawerMode = 'push';

  public menuSvg: SVGIcon = menuIcon;
  public logoutIcon: SVGIcon = logoutIcon;

  public locked: boolean = false;
  public expanded: boolean = true;
  public loggedIn: boolean = false;
  public mini: boolean = true;

  public noMargin: boolean = true;

  public configuration = environment;

  public autoCollapse = false;
  // Collection with the indices of all expanded items
  public expandedIndices = [];

  @ViewChild(DrawerComponent)drawer: DrawerComponent;

  public nodes: Array<AppDrawerItem> = [
    { text: 'AppComponent.Analytics', svgIcon: starOutlineIcon, id: 1, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.General_Dashbord', svgIcon: starOutlineIcon, path: 'dashboard', id: 2, parentId: 1, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Tactical_Dashbord', svgIcon: starOutlineIcon, path: 'dashboard/2', id: 3, parentId: 1, forDeveloper: true },
    { text: 'AppComponent.Operational_Dashbord', svgIcon: starOutlineIcon, path: 'dashboard/1', id: 4, parentId: 1, forDeveloper: true },

    { separator: true },

    { text: 'AppComponent.Availability', svgIcon: starOutlineIcon, id: 5, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.AoT_Balance', svgIcon: starOutlineIcon, id: 6, path: 'aot/balance', parentId: 5, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.AoT_Balance_Report', svgIcon: starOutlineIcon, id: 7, path: 'aot/balanceReport', parentId: 5, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Demand_Report', svgIcon: starOutlineIcon, id: 8, path: 'aot/demand', parentId: 5, forDeveloper: true, forPlanner: true },
    { text: 'AoT.LogForecast', svgIcon: starOutlineIcon, id: 9, path: 'aot/logForecast', parentId: 5, forDeveloper: true, forPlanner: true },

    { separator: true },

    { text: 'AppComponent.Model', svgIcon: starOutlineIcon, id: 10, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Models', svgIcon: starOutlineIcon, id: 11, path: 'model/model', parentId: 10, forDeveloper: true },
    { text: 'AppComponent.Admin_EntityActivity', svgIcon: starOutlineIcon, id: 12, path: 'model/entity/activity', parentId: 10, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Admin_EntityResource', svgIcon: starOutlineIcon, id: 13, path: 'model/entity/material', parentId: 10, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Admin_EntityGroup', svgIcon: starOutlineIcon, id: 14, path: 'model/entity-group', parentId: 10, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Admin_EntityProperty', svgIcon: starOutlineIcon, id: 15, path: 'model/entity-property', parentId: 10, forDeveloper: true, forPlanner: true },

    { separator: true },

    { text: 'EntityTranslation.Tactical', svgIcon: starOutlineIcon, id: 17,  forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Plan', svgIcon: starOutlineIcon, id: 18, path: 'tactical/plan', parentId: 17, forDeveloper: true, forPlanner: true },

    { separator: true },

    { text: 'AppComponent.Saw_Pattern', svgIcon: starOutlineIcon, id: 19, path: 'saw-pattern', forDeveloper: true },

    { separator: true },

    { text: 'AppComponent.Glulam', svgIcon: starOutlineIcon, id: 20, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Glulam_Planning', svgIcon: starOutlineIcon, id: 21, path: 'glulam', parentId: 20, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Glulam_Planning_Statical', svgIcon: starOutlineIcon, id: 22, path: 'glulam/statical', parentId: 20, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Glulam_Operator', svgIcon: starOutlineIcon, id: 23, path: 'glulam/operator', parentId: 20, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Beams', svgIcon: starOutlineIcon, id: 24, path: 'glulam/beams', parentId: 20, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Glue_Plans', svgIcon: starOutlineIcon, id: 25, path: 'glulam/glueplans', parentId: 20, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Glulam_Scheduler_Statical', svgIcon: starOutlineIcon, id: 26, path: 'glulam/statical/scheduler', parentId: 20, forDeveloper: true, forPlanner: true },

    { separator: true },

    { text: 'AppComponent.Optimization', svgIcon: starOutlineIcon, id: 27, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.OptimizeSequencer', svgIcon: starOutlineIcon, id: 9, path: 'optimization/sequencer', parentId: 27, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.OptimizeRuns', svgIcon: starOutlineIcon, id: 28, path: 'optimization/runs', parentId: 27, forDeveloper: true, forPlanner: true },

    { separator: true },

    { text: 'AppComponent.CLT', svgIcon: starOutlineIcon, id: 29, forDeveloper: true },
    { text: 'AppComponent.ShapeList', svgIcon: starOutlineIcon, id: 30, path: 'clt/shape-list', parentId: 29, forDeveloper: true },
    { text: 'AppComponent.PlanList', svgIcon: starOutlineIcon, id: 31, path: 'clt/plan-list', parentId: 29, forDeveloper: true },
    { text: 'AppComponent.ProfileList', svgIcon: starOutlineIcon, id: 32, path: 'clt/profile-list', parentId: 29, forDeveloper: true },
    { text: 'AppComponent.EditMotherboard', svgIcon: starOutlineIcon, id: 33, path: 'clt/edit-motherboard', parentId: 29, forDeveloper: true },
    { text: 'AppComponent.EditPlan', svgIcon: starOutlineIcon, id: 34, path: 'clt/edit-plan', parentId: 29, forDeveloper: true },
    { text: 'AppComponent.EditProfile', svgIcon: starOutlineIcon, id: 35, path: 'clt/edit-profile', parentId: 29, forDeveloper: true },

    { separator: true },

    { text: 'AppComponent.Operative', svgIcon: starOutlineIcon, id: 36, forDeveloper: true, forPlanner: true, forOperator: true },
    { text: 'AppComponent.Production_Order', svgIcon: starOutlineIcon, id: 37, path: 'prod-order', parentId: 36, forDeveloper: true, forPlanner: true, forOperator: true },
    { text: 'AppComponent.Production_Order_ScheduleNg', svgIcon: starOutlineIcon, id: 38, path: 'prod-order-schedule-ng', parentId: 36, forDeveloper: true, forPlanner: true, forOperator: true },
    { text: 'AppComponent.Production_Order_Calendar', svgIcon: starOutlineIcon, id: 39, path: 'prod-order-calendar-ng', parentId: 36, forDeveloper: true, forPlanner: true, forOperator: true },
    { text: 'AppComponent.Machine', svgIcon: starOutlineIcon, id: 40, path: 'machine', parentId: 36, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.MachineGroup', svgIcon: starOutlineIcon, id: 41, path: 'machine-group', parentId: 36, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Instruction', svgIcon: starOutlineIcon, id: 42, path: 'instruction', parentId: 36, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.InstructionLine', svgIcon: starOutlineIcon, id: 43, path: 'instruction-line', parentId: 36, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Schedule', svgIcon: starOutlineIcon, id: 44, path: 'schedule', parentId: 36, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Shift', svgIcon: starOutlineIcon, id: 45, path: 'shift', parentId: 36, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Deviation', svgIcon: starOutlineIcon, id: 46, path: 'deviation', parentId: 36, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Device', svgIcon: starOutlineIcon, id: 47, path: 'device', parentId: 36, forDeveloper: true, forPlanner: true },

    { separator: true },

    { text: 'AppComponent.Stock', svgIcon: starOutlineIcon, id: 48, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Stock_Location', svgIcon: starOutlineIcon, id: 49, path: 'stock/stocklocation', parentId: 48, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Stock_Trans_Type', svgIcon: starOutlineIcon, id: 50, path: 'stock/stocktranstype', parentId: 48, forDeveloper: true, forPlanner: true },
    { text: 'AppComponent.Handeling_Unit_Report', svgIcon: starOutlineIcon, id: 51, path: 'stock/handelingunitreport', parentId: 48, forDeveloper: true, forPlanner: true },

    { separator: true },

    { text: 'AppComponent.Log_Class', svgIcon: starOutlineIcon, id: 52, path: 'log-class', forDeveloper: true },

    { separator: true },

    { text: 'AppComponent.Reports', svgIcon: starOutlineIcon, id: 53, path: 'report', forDeveloper: true, forPlanner: true },

    { separator: true, forDeveloper: true },
    // { text: 'Notifications', svgIcon: bellIcon, path: 'machine', forDeveloper: true },
    // { text: 'Calendar', svgIcon: calendarIcon },
    // { separator: true },
    // { text: 'Attachments', svgIcon: envelopeLinkIcon },
    // { text: 'Favourites', svgIcon: starOutlineIcon }
  ];

  public authenicatedNodes: Array<AppDrawerItem> = [];
  currentUrl: string;
  user: any;
  constructor(
    public router:Router,
    public userService: UserService,
    public translateService:TranslateService,
    public titleService: Title,
    public authService: AuthService,
    private location: Location) {this.currentUrl = this.location.path().slice(1); }

  public isItemExpanded = (item: any): boolean => {
    return item.expanded;
  };

  async ngOnInit() {
    if (environment.environment !== 'prod') {
      let title = this.titleService.getTitle();

      if (environment.environment != null && environment.environment !== '') {
        title = title + ` (${environment.environment.toUpperCase()})`;
      }

      this.titleService.setTitle(title);
    }

    this.setLoginState();

    this.user = await this.userService.getUser();

    this.confLockState(this.user.isMenuExpanded);

    this.setMenuSelection(this.user);

  }

  setMenuSelection(user){
    this.authenicatedNodes = this.nodes.filter(function f(o) {
      if (user.isDeveloper && o.forDeveloper) {
        return true;
      }

      if (user.isPlanner && o.forPlanner) {
        return true;
      }

      if (user.isOperator && o.forOperator) {
        return true;
      }

      return false;
    });

    this.authenicatedNodes.forEach(x=>{    
      if(x.path === this.currentUrl){
       x.selected = true;
       if(x.parentId){
        let parent = this.authenicatedNodes.find(z=>z.id == x.parentId);
        parent.expanded = true;
       }
      }
      else{
        x.selected = false;
      }
     });
  }

  public confLockState(isLocked: boolean) {
    this.autoCollapse = !this.autoCollapse;
    this.locked = isLocked;

    if (isLocked) {
      this.autoCollapse = false;
      this.mode = 'push';
      this.mini = false;
      this.drawer.toggle(true);
    } else {
      console.log('confLockState: False')
      this.autoCollapse = false;
      this.mode = 'overlay';
      this.mini = false;
      this.drawer.toggle(true);
    }
  }

  // translateNodes(authenticatedNodes) {
  //   for (const node of authenticatedNodes) {
  //     this.translateService.get(node.text).subscribe(d => {
  //       node.text = d;
  //     });
  //   };
  // }

  public onSelect(ev: DrawerSelectEvent): void {
    const current = ev.item.id;
    this.authenicatedNodes.forEach(x=>x.selected = false);
    if(!ev.item.parentId){
     ev.item.expanded = true;
     ev.item.selected = true;
     this.autoCollapse = false;    
    }
    const path = (ev.item as AppDrawerItem).path;
      if (path) {
        this.currentUrl = path;
        this.router.navigate([this.currentUrl]);
        this.autoCollapse = this.mode=='overlay' ? true : false; 
        ev.item.expanded = false;   
        return;
      }
      
  }

  public toggleExpanded() {
    this.setMenuSelection(this.user);
    this.drawer.toggle();
  }

  setLoginState() {
    this.loggedIn = this.authService.isLoggedIn();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }
}

interface AppDrawerItem extends DrawerItem {
  path?: string;
  forDeveloper?: boolean;
  forPlanner?: boolean;
  forOperator?: boolean;
  expanded?: boolean;
}
