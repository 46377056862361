import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EntityRelationDirection, IEntity, IEntityRelation } from '@app/core/services/http-services/model/entity.service';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { ChainingProdOrderDTO } from '@app/core/models/chaining-prodorderDTO';
import { NewProdOrderInputDTO } from '@app/core/models/new-prod-order-input-dto';
import { CreateChainProdOrderInput, ProductActivityDetails } from '@app/core/models/prod-order';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Guid } from 'guid-typescript';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { SVGIcon, warningTriangleIcon } from '@progress/kendo-svg-icons';

marker('Operative.CreateChainPOInProgress');

@Component({
  selector: 'app-create-multi-step-prod-order',
  templateUrl: './create-multi-step-prod-order.component.html',
  styleUrls: ['./create-multi-step-prod-order.component.css']
})
export class CreateMultiStepProdOrderComponent extends DialogContentBase implements OnInit {
  public prod: ProductActivityDetails[] = null;
  public productAndQTy: ChainingProdOrderDTO[] = [];
  public disableGenerateBtn = false;
  public loading = false;
  useReportedQty: boolean = false;

  form: UntypedFormGroup = new UntypedFormGroup({});

  public warningTriangleIcon:SVGIcon = warningTriangleIcon;

  constructor(
    @Inject(DialogRef) public data: CreateMultiStepData,
    private dialogRef: DialogRef,
    public prodOrderService: ProdOrderService,
    private appNotificationService: AppNotificationService,
    private fb: UntypedFormBuilder
  ) {
    super(dialogRef);

    this.form = this.fb.group({
      numberOfSteps: this.fb.control(1),
      steps: this.fb.array([])
    });
  }

  get steps() {
    return this.form.controls['steps'] as UntypedFormArray;
  }

  ngOnInit(): void {
    this.form.get('numberOfSteps').disable();

    const materialIndexes: Set<number> = new Set<number>();

    this.data.entityRelations
      .filter((d) => d.isChaining)
      .forEach((rel) => {
        if (!materialIndexes.has(rel.productIndex)) {
          materialIndexes.add(rel.productIndex);
          this.productAndQTy.push(<ChainingProdOrderDTO>{
            plannedQty: rel.plannedQty,
            productIndex: rel.productIndex,
            reportedQty: rel.reportedQty
          });
        }
      });

    this.prodOrderService.getActivityForMaterials(materialIndexes, EntityRelationDirection.CONSUMES)
      .subscribe(
        res => {
          for (const f of res) {
            this.addStepForm(f, this.getQty(f.productIndex), this.getReportedQty(f.productIndex));
          }
          this.prod = res;
        });
  }

  private addStepForm(f: ProductActivityDetails, qty: number, reportedQty: number) {
    const formGroup = this.fb.group({
      activityIndex: [f.activitys[0]?.index ?? null, Validators.required],
      productIndex: [f.productIndex ?? null, Validators.required],
      plannedQty: [qty ?? null, Validators.required],
      reportedQty: [reportedQty ?? null]
    });

    if (f.activitys.length < 1) {
      formGroup.get('activityIndex').disable();
    }

    this.steps.push(formGroup);
  }

  save() {
    this.loading = true;
    this.disableGenerateBtn = true;

    const chainPOInput = <CreateChainProdOrderInput>{
      chainingProdorders: this.steps.value
        .filter((v) => v.activityIndex)
        .map(
          (v) =>
            <ChainingProdOrderDTO>{
              activityIndex: v.activityIndex,
              productIndex: v.productIndex,
              plannedQty: v.plannedQty,
              reportedQty: v.reportedQty
            }
        ),
      newProdOrderInput: this.data.prodOrder,
      prodOrderId: this.data.prodOrderId,
      siteId: this.data.siteId,
      useReportedQty: this.useReportedQty
    };

    this.prodOrderService.createPOChain(chainPOInput).subscribe({
      next: (res) => {
        if (res) {
          this.appNotificationService.notifyInfoAppChanel('Operative.CreateChainPOInProgress');
          this.dialogRef.close(true);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.disableGenerateBtn = false;
      }
    });
}

  close() {
    this.dialogRef.close(false);
  }

  public getControl(i: number, name: string) {
    return this.steps.at(i).get(name) as UntypedFormControl;
  }

  public getQty(productIndex: number): number {
    return this.productAndQTy.find((p) => p.productIndex === productIndex)?.plannedQty ?? 0;
  }

  public getBatchSize(i: number, activities: IEntity[]): number {
    const actIndex = this.getControl(i, 'activityIndex').value;
    return activities.find((a) => a.index === actIndex)?.batchSize;
  }

  public calcNoProdOrders(qty: number, batchSize: number): number {
    return batchSize > 0 ? Math.ceil(qty / batchSize) : 1;
  }

  public getReportedQty(productIndex: number): number {
    return this.productAndQTy.find((p) => p.productIndex === productIndex)?.reportedQty ?? 0;
  }

  public toggleUseReportedQtyCheckbox() {
    this.useReportedQty = !this.useReportedQty;
  }
}

export class CreateMultiStepData {
  public siteId: Guid;
  public prodOrder: NewProdOrderInputDTO;
  public entityRelations: IEntityRelation[];
  public prodOrderId: number;
}
