<div class="section-header">
  <button kendoButton
  [svgIcon]="plusCircleIcon"
  size="large"
  fillMode="link"
  themeColor="primary"
  *ngIf="!isOperator"
  (click)="editGluingPlan(null)">
  </button>
  <h3 translate>GluePlan.GluePlans</h3>
</div>
<div class="gp-card-container">
  <kendo-card
    *ngFor="let gluingPlan of gluingPlans; let i = index"
    width="200px"
    style="display: inline-block;"
    onkeyup
    (click)="onSelectionChange(gluingPlan)">
    <kendo-card-header class="row">
      <h4 kendoCardTitle>{{ gluingPlan.gluingPlanID }} - {{ gluingPlan.name }}</h4>
      <kendo-svg-icon style="align-items: flex-end;" [icon]="pencilIcon" themeColor="warning" *ngIf="gluingPlan.gluingPlanID === selectedPlan?.gluingPlanID"></kendo-svg-icon>
    </kendo-card-header>
    <kendo-card-body style="display: flex; flex-direction: column;">
      <div style="display: flex; flex-direction: row;">
        <div
          [style.color]="getStateColor(gluingPlan.glueSetStateId)" >
          {{getStateText(gluingPlan.glueSetStateId) | translate | uppercase }}
        </div>
        <div style="flex-grow: 1;"></div>
        <span [title]="'GluePlan.QuantityTitle' | translate:{beamsProduced:gluingPlan.beamsProduced,beamsRejected:gluingPlan.beamsRejected,totalBeams:gluingPlan.totalBeams}">
          <span [style.color]="getStateColor(5)">{{gluingPlan.beamsProduced}}</span>
          <span
            *ngIf="gluingPlan.beamsRejected && gluingPlan.beamsRejected > 0"
            [style.color]="getStateColor(6)">+{{gluingPlan.beamsRejected}}</span>/{{ gluingPlan.totalBeams }}</span>
      </div>
      <div style="display: flex; flex-direction: row;">
        <div>{{ gluingPlan.dimension?.width }}</div>
        <div style="flex-grow: 1;"></div>
        <div *ngIf="gluingPlan.prodOrderID">{{ gluingPlan.prodOrderID }}</div>
      </div>
      <div style="display: flex; flex-direction: row;" kendoTooltip>
        <div>
          {{ gluingPlan.plannedExecutionStartDate | siteDateFormat}}
        </div>
        <div style="flex-grow: 1;"></div>
        <kendo-svg-icon
          [icon]="clipboardTextIcon"
          *ngIf="gluingPlan.instruction"
          [title]="gluingPlan.instruction">
        </kendo-svg-icon>
      </div>
    </kendo-card-body>
    <kendo-card-actions>
      <button kendoButton
      [svgIcon]="downloadIcon"
      themeColor="secondary"
      [disabled]="gluingPlan.glueSetStateId==1"
      (click)="download(gluingPlan)">
    </button>
      <kendo-dropdownbutton
      [data]="rowActions"
      themeColor="secondary"
      [svgIcon]="printIcon"
      >
        <ng-template kendoDropDownButtonItemTemplate let-dataItem>
          <kendo-svg-icon [icon]="dataItem.svgIcon"></kendo-svg-icon> {{ dataItem.name | translate }}
        </ng-template>
      </kendo-dropdownbutton>
      <div style="flex-grow: 1;"></div>
      <button kendoButton
      [svgIcon]="trashIcon"
      themeColor="error"
      (click)="openConfirmationDialog(gluingPlan, i)"
      [disabled]="isOperator || gluingPlan.glueSetStateId !== 1">
    </button>
      <button kendoButton
        [svgIcon]="pencilIcon"
        themeColor="primary"
        [disabled]="isOperator"
        (click)="editGluingPlan(gluingPlan)">
      </button>
    </kendo-card-actions>
  </kendo-card>
</div>
<app-confirmation-dialog *ngIf="itemToRemove"
                         [msg] = "'GluePlan.GluePlanConfirmDelete'"
                         (conformationDialogCloseEvent) = "confirmRemove($event)">
</app-confirmation-dialog>


<app-confirmation-dialog *ngIf="selectedGluingPlanAfterGluesetChange"
                         [msg] = "'GluePlan.alert_gluing_plan_save'"
                         (conformationDialogCloseEvent) = "confirmUnsavedGlueSet($event)">
</app-confirmation-dialog>
