import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IGluPlanModalInput } from "@app/core/models/glueplan-modal-input.model";
import { GluingPlan } from "@app/core/models/gluing-plans.model";
import { BaseService } from "@app/core/services/custom-services/base.service";
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { AppNotificationService } from "@app/core/services/custom-services/notification.service";
import { BlockProductionWizardComponent } from "@app/modules/gluelam/controls/gluelam-planning-statical/block-production/block-production-wizard/block-production-wizard.component";
import { TranslateService } from "@ngx-translate/core";
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";


@Injectable()
export class BlockProductionService extends BaseService {
  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService,
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService){
    super(http,conf,notificationService);
  }

  openDialog(plan: GluingPlan): DialogRef {

    const dialogRef = this.dialogService.open({
      content: BlockProductionWizardComponent,
      title: this.translateService.instant('GluePlan.BlockProduction'),
      width: '40vw',
      height: '65vh'
    });

    const content = dialogRef.content.instance as BlockProductionWizardComponent;
    content.data = <IGluPlanModalInput> {
      isNew: false,
      gluingPlan: { ...plan },
      isContinuousPressGroup: false,
      isStaticalPressGroup: true,
      isStaticalScheduler: false
    };

    return dialogRef;
  }
}
