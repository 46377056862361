import { Component, OnChanges, Output, EventEmitter, SimpleChanges, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { AddLineParameter, Demandline } from '@app/core/models/demand-line.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { ProductionFileService } from '@app/core/services/http-services/gluelam/production-file.service';
import { EntityService } from '@app/core/services/http-services/model/entity.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { BeamMaterialGlueSet, BeamMaterialGlueSetLayer } from '@app/core/models/beam-material-glue-set.model';
import { BeamMaterial } from '@app/core/models/beam-material.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { ValidataionResult } from '@app/core/models/validation-result.model';
import { CopyService, DataForCutBM, DataForCutBML } from '@app/core/services/custom-services/copy.service';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { environment } from '@environment/environment';
import { CordHelper } from '../../beam-material-gluset/CordHelper';
import { GlusetMenuEvent } from '../../beam-material-gluset/beam-material-glueset.component';
import { BeamMaterialLineEditorService } from '../../beam-material-gluset/beam-material-line-menu.service';
import { BeamSplitViewModalComponent } from '../../beam-split-view-modal/beam-split-view-modal.component';
import { BMGSprintComponent } from '../../print/bmgs-print.component';
import { Guid } from '@app/core/models/Guid';
import { OverlayRef } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { BeamMaterialService } from '@app/modules/gluelam/services/beam-material.service';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { BeamMaterialType } from '@app/core/models/beam-material-type.model';
import { IManualBeamMaterialResult } from '@app/core/models/manual-beam-material.dto';
import { ManualBeamMaterialDialogComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/manual-beam-material-dialog/manual-beam-material-dialog.component';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';

import {
  SVGIcon,
  trashIcon,
  downloadIcon,
  displayFlexIcon,
  menuIcon,
  checkCircleIcon,
  pencilIcon,
  warningCircleIcon,
  plusCircleIcon
} from "@progress/kendo-svg-icons";

marker('GlueSet.Height');
marker('GlueSet.Length');
marker('GlueSet.ALL_UNITS_ROW_PLANNED');
marker('GlueSet.BMGS_CAN_NOT_ADD_MANY_TEMPLATES');
marker('GlueSet.BMGS_Edit_Error');
marker('GlueSet.BM_OF_DIFFERENT_DIM');
marker('GlueSet.BOR_LAMMINAS_DIFFERNET_FROM_BM');
marker('GlueSet.BOR_PLANE_THICKNESS_DIFFERNET_FROM_BM');
marker('GlueSet.Total_Glue_Set_Hight_Exided');
marker('GlueSet.Total_Glue_Set_Length_Exided');
marker('GlueSet.BMGS_Removed');
marker('GlueSet.NOT_ALLOWED_TO_COMBINE_PLANING');
marker('GlueSet.UNDER_MIN_HIGHT');
marker('GlueSet.NOT_ALLOWED_TO_COMBINE_FINAL_HIGHT');
marker('GlueSet.NOT_ALLOWED_TO_COMBINE_WIDTH');
marker('GlueSet.NOT_ALLOWED_TO_COMBINE_SPLIT_AND_NOSPLIT_BM');
marker('GlueSet.BOR_CERT_DIFFERNET_FROM_BM');
marker('GlueSet.BOR_MAIN_PRODUCT_AND_PROFILE_DIFFERNET_FROM_BM');
marker('GlueSet.Total_Glue_Set_Width_Exceeded');
marker('GlueSet.NOT_ALLOWED_TO_COMBINE_SPECIES');

@Component({
  selector: 'app-beam-material-gluset-statical',
  templateUrl: './beam-material-gluset-statical.component.html',
  styleUrls: ['./beam-material-gluset-statical.component.css']
})

export class BeamMaterialGlueSetStaticalEditor implements OnInit, OnChanges, OnDestroy {
  @Input() bmgs: BeamMaterialGlueSet = null;
  @Input() plan: GluingPlan;
  @Input() gsWidth = 560;
  @Input() machine: IMachineDTO | null;
  @Input() editable = true;
  @Input() isLeftMajor: string;
  @Input() lamminaDim: BeamLamminaDimension;
  @Input() isOperator: boolean = false;
  @Input() isDeleteAllowed = true;

  @Output() addEmptyBmgs = new EventEmitter<boolean>();

  @Output() deleteBeamMaterialGlueSet = new EventEmitter<BeamMaterialGlueSet>();

  @Output() editBeamMaterial: EventEmitter<{
    bmgs: BeamMaterialGlueSet;
    bm: BeamMaterial;
  }> = new EventEmitter();

  @Output() removeBeamMaterialGlueSet: EventEmitter<{
    bmgs: BeamMaterialGlueSet;
  }> = new EventEmitter();

  @Output() updateBeamMaterialGlueSet: EventEmitter<{
    bmgs: BeamMaterialGlueSet;
  }> = new EventEmitter();

  @Output() calculateUnits: EventEmitter<{}> = new EventEmitter();
  @Output() updateGluePlan: EventEmitter<{}> = new EventEmitter();

  @Output('openBeamMaterialLineMenu')
  // eslint-disable-next-line indent
  openBeamMaterialLineMenuInt: EventEmitter<{
    bm: BeamMaterial;
    bml: BeamMaterialLine;
    x: number;
    y: number;
  }> = new EventEmitter();

  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  bmlMenuRef: OverlayRef;
  containerHight = 300;
  hightSideLayerPX = 25;
  widthSideMM = environment.maxHight;
  ramConfigurationGlobal : IPressbedRamConfigurationDTO[] = [];
  ramConfiguration : IPressbedRamConfigurationDTO[] = [];
  ch: CordHelper;
  isSplit = false;
  glueSetStateEnum = GlueSetState;
  gluingWidth = 50;

  //Icons
  trashIcon: SVGIcon = trashIcon;
  menuIcon: SVGIcon = menuIcon;
  checkCircleIcon: SVGIcon = checkCircleIcon;
  warningCircleIcon: SVGIcon = warningCircleIcon;
  pencilIcon: SVGIcon = pencilIcon;
  plusCircleIcon: SVGIcon = plusCircleIcon;

  rowActions = [
    {
      name: "GlueSet.SplitView",
      svgIcon: displayFlexIcon,
      click: (): void => {
        this.splitView();
      },
    },
    {
      name: "GlueSet.Download",
      svgIcon: downloadIcon,
      disabled: !(this.bmgs?.beamMaterialGlueSetState?.glueSetStateId > 1),
      click: (): void => {
        this.download();
      },
    },
    {
      name: "App.Delete",
      svgIcon: trashIcon,
      disabled: !this.isOperator && this.bmgs?.beamMaterialGlueSetState?.glueSetStateId > 1,
      click: (): void => {
        this.onDelete();
      },
    }
  ];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialog: DialogService,
    private readonly notificationService: AppNotificationService,
    private readonly bmlDialogService: BeamMaterialLineEditorService,
    private readonly productionFileService: ProductionFileService,
    private readonly copyService: CopyService,
    private readonly bmgsService: GlueSetService,
    private readonly gluelamStaticalMediatorService:GluelamStaticalMediatorService,
    private readonly beamMaterialService: BeamMaterialService,
    private readonly entitySimpleService: EntityService,
    private readonly dimensionService:DimensionService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.gluelamStaticalMediatorService.glueplanOffsetChanged.pipe(takeUntil(this.destroy$)).subscribe((gluePlan) => {
      if (gluePlan != null && gluePlan?.gluingPlanID === this.plan.gluingPlanID) {
        this.getRamConfig();
      }
    });

    this.gluelamStaticalMediatorService.glueplanRamConfigurationChange.pipe(takeUntil(this.destroy$)).subscribe((config:IPressbedRamConfigurationDTO[]) => {
      if (config != null) {
        this.ramConfigurationGlobal = [...config];
      }
      this.getRamConfig();
    });

    if (this.bmgs.layers.length === 0) {
      this.onAddNewLayer();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bmgs && this.gsWidth) {
      this.setupCordSystem();
    }
  }

  calcY(i: number): number {
    return i * (this.containerHight + 10);
  }

  calcYForLayer(index: number, bmgsLayer: BeamMaterialGlueSetLayer): number {
    let x = 0;

    for (let i = 0; i < index; i++) {
      x += bmgsLayer.BeamMaterials[i].getHight();
    }

    return this.ch.getHight(x);
  }

  setupCordSystem() {
    if (this.bmgs) {
      this.ch = new CordHelper(
        this.containerHight,
        this.machine.maxPressHight,
        this.gsWidth,
        this.plan.length ?? 6000
      );

      this.getRamConfig();
    }
  }

  adjustBMLAndSetCords() {
    if (this.bmgs) {
      this.bmgs.AdjustBeamMaterialLines();
      this.setupCordSystem();
    }
  }

  calcBMSideX(bmgsl: BeamMaterialGlueSetLayer, index: number): number {
    let x = 0;

    for (let i = 0; i < index; i++) {
      x += bmgsl.BeamMaterials[i].getHight();
    }

    return this.ch.getHight(x);
  }

  getContainerHight() {
    return this.bmgs.layers.length * (this.containerHight + 20);
  }

  getHeightAllocation(totalHeight: number) {
    return totalHeight;
  }

  onSave() {
    this.notificationService.notifySucsessAppChanel(
      'GlueSet.BMGS_ProductionOrder_Created'
    );
    this.bmgs.save = true;
    this.updateBeamMaterialGlueSet.emit({ bmgs: this.bmgs });
  }

  onDelete() {
    this.deleteBeamMaterialGlueSet.emit(this.bmgs);
  }

  onAddNewLayer() {
    this.bmgs.layers.unshift(new BeamMaterialGlueSetLayer([]));
    this.updateGluePlan.emit();
  }

  onDeleteLayer(index:number) {
    this.bmgs.layers.splice(index, 1);
    this.bmgs.save = true;

    if (this.bmgs.layers.length === 0) {
      this.deleteBeamMaterialGlueSet.emit(this.bmgs);
    }

    this.updateGluePlan.emit();
    this.calculateUnits.emit({});
  }

  onPrint() {
    const width = 1300;
    this.dialog.open({
      content: BMGSprintComponent,
      width: `${width}px`,
      height: 'auto',
    });
  }

  onRepeatsChanged(number: string) {
    const rep = Number.parseInt(number);
    this.bmgs.performedTimes = Number.isInteger(rep)
      ? rep
      : this.bmgs.performedTimes;
    this.updateGluePlan.emit();
    this.calculateUnits.emit({});
  }

  openBMGSContextMenu(layer: BeamMaterialGlueSetLayer, mouseEvent: MouseEvent, index:number) {
    this.openContextMenu(<GlusetMenuEvent>{
      bm: null,
      bml: null,
      layer,
      mouseEvent
    }, index);

    return false;
  }

  openContextMenu(event: GlusetMenuEvent, index:number) {
    if (this.bmgs.beamMaterialGlueSetState.glueSetStateId > 1) {
      return;
    }
    const bmlDialog = this.bmlDialogService.open(event.mouseEvent, {
      bm: event.bm,
      bml: event.bml,
      height: '400px',
      width: '600px',
      machine: this.machine,
      isStaticalGluPlan: true
    });

    bmlDialog.onCutBeamMaterial.subscribe((data: DataForCutBM) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      data.sourceGS = this.bmgs;
      this.updateGluePlan.emit();
      this.calculateUnits.emit({});
      bmlDialog.close();
    });

    bmlDialog.onCutBeamMaterialLine.subscribe((data: DataForCutBML) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      data.sourceGS = this.bmgs;
      this.updateGluePlan.emit();
      this.calculateUnits.emit({});
      bmlDialog.close();
    });

    bmlDialog.onPasteCutBeamMaterial.subscribe((data: DataForCutBM) => {
      // add new bm and bml
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      let enableAdd = true;
      this.loading.emit(true);

      data.copiedBMs.forEach((copyBm) => {
        if (event.bm) {
          enableAdd = this.AddLineToBM(copyBm.beamMaterialLines, event.bm, index);
        } else {
          if (copyBm.BeamMaterialType.IsManual) {
            this.addLineToNewBMForManualBeam(copyBm.beamMaterialLines, copyBm.width, copyBm.height, index, copyBm.length);
          } else {
            enableAdd = this.AddLineToNewBM(copyBm.beamMaterialLines, copyBm.width, copyBm.height, index);
          }
        }
      });

      if (enableAdd) {
        this.copyService.reset();
      }

      this.loading.emit(false);

      bmlDialog.close();
    });

    bmlDialog.onPasteCutBeamMaterialLine.subscribe((data: DataForCutBML) => {
      let couldAdd = true;
      this.bmgs.save = true;
      if (event.bm) {
        couldAdd = this.AddLineToBM(data.copiedBMLs, event.bm, index);
      } else {
        couldAdd = this.AddLineToNewBM(data.copiedBMLs, event.bm?.width ?? data.sourceBM.width, event.bm?.height ?? data.sourceBM.height, index);
      }

      if (couldAdd) {
        this.copyService.reset();
      }

      bmlDialog.close();
    });

    bmlDialog.onRemoveBeamMaterial.subscribe((bm) => {
      this.RemoveBeamMaterial(bm);
      this.bmgs.save = true;
      // TODO: check if BM is last BM associated with template, if yes, remove template as well
    });

    bmlDialog.onRemoveBeamMaterialLine.subscribe((bml: BeamMaterialLine) => {
      this.RemoveBeamMaterialLine(bml, event.bm);
      this.bmgs.save = true;
    });

    bmlDialog.onMoveUppBeamMaterial.subscribe((bm) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      this.reorderBeamMaterialUpp(bm, this.bmgs);
    });

    bmlDialog.onAddLineToBM.subscribe((param: AddLineParameter) => {
      this.bmgsService.isDirty = true;
      this.bmgs.save = true;
      if (event.bm) {
        const newLine = this.createBMLFromBOL(param.demand);
        this.AddLineToBM([newLine], event.bm, index);
      } else {
        const newLine = this.createBMLFromBOL(param.demand);

        const width = 0;
        const height = this.beamMaterialService.calculateHeight(param.demand.DeafultLamminaPlaneThicknes, param.demand.NumberOfLaminas);

        this.AddLineToNewBM([newLine], width, height, index);
      }
    });

    bmlDialog.onAddLineToBMGS.subscribe((param: AddLineParameter) => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      let width = 0;
      const newLine = this.createBMLFromBOL(param.demand);

      this.entitySimpleService.get(param.demand.materialIndex).subscribe(material => {
        if (material.standardProperties.width) {
          width = material.standardProperties.width;
        } else {
          this.dimensionService.getBeamLamminaDimention(param.demand.BeamLamminaDimensionID).subscribe(dimension => {
            width = dimension.width ?? 0;
          });
        }

        const height = this.beamMaterialService.calculateHeight(param.demand.DeafultLamminaPlaneThicknes, param.demand.NumberOfLaminas);

        this.AddLineToNewBM([newLine], width, height, index);
      });
    });

    bmlDialog.onMoveDownBeamMaterial.subscribe((bm) => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      this.reorderBeamMaterialDown(bm, this.bmgs);
    });

    bmlDialog.onPasteBM.subscribe((bm) => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      this.bmgs.layers[index].BeamMaterials.push(bm);
      this.updateGluePlan.emit();
      this.calculateUnits.emit({});
    });

    bmlDialog.onAdjustBMGS.subscribe(x => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;
      this.adjustBMLAndSetCords();
    });

    bmlDialog.onAddTestPsc.subscribe(bm => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;

      this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset).subscribe((calcLenght: number) => {
        bm.length = calcLenght;
        this.adjustBMLAndSetCords();
        this.copyService.activeSave = false;
      });
    });

    bmlDialog.onRemoveTestPsc.subscribe(bm => {
      this.bmgs.save = true;
      this.bmgsService.isDirty = true;

      this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset).subscribe((calcLenght: number) => {
        bm.length = calcLenght;
        this.adjustBMLAndSetCords();
        this.copyService.activeSave = false;
      });
    });

    bmlDialog.onAddManualBeamMaterialToBMGS.subscribe((demandlines : Demandline[]) => {
      const dialogRef = this.dialog.open({
        content: ManualBeamMaterialDialogComponent,
        width: '600px',
        height: 'auto',
      });

      const content = dialogRef.content.instance as ManualBeamMaterialDialogComponent;
      content.data = demandlines

      dialogRef.result.subscribe((result : IManualBeamMaterialResult) => {
        if (result instanceof DialogCloseResult) {
          dialogRef.close();
        } else if(result.success) {
          this.bmgs.save = true;
          this.bmgsService.isDirty = true;
          const beamMaterialLines: BeamMaterialLine[] = [];

          result.manulaBeamMaterial.demands.forEach((demand, i) => {
            const demandLine = demandlines.find(d => d.BeamOrderRowID === demand.beamOrderRowID && d.orderID === demand.orderId);
            for (let i = 0; i < demand.qty; i++) {
              beamMaterialLines.push(this.createBMLFromBOL(demandLine));
            }
          });

          this.entitySimpleService.get(demandlines[0].materialIndex).subscribe(material => {
            if (material) {
              const width = material.standardProperties?.width ?? 0;
              const height = this.beamMaterialService.calculateHeight(demandlines[0].DeafultLamminaPlaneThicknes, demandlines[0].NumberOfLaminas);
              this.addLineToNewBMForManualBeam(beamMaterialLines, width, height, index, result.manulaBeamMaterial.length);
            }
          });
        }
      });
    });
  }

  async download() {
    const blob = await this.productionFileService.productionFileForGlueset(this.bmgs.beamMaterialGlueSetID);
    DownloadFileHelper.downloadFile(blob, `GlueSet-${this.bmgs.beamMaterialGlueSetID}-${new Date(Date.now()).toISOString().split('T')[0]}`);
  }

  splitView() {
    const dialogRef = this.dialog.open({
      content: BeamSplitViewModalComponent,
      height: '50%',
      width: '50%'
    });

    const content = dialogRef.content.instance as BeamSplitViewModalComponent;
    content.data = {
      beamMaterials: this.bmgs.beammaterials,
      bmgsLength: this.bmgs.length
    }
  }

  getRamConfig(): void {
    this.ramConfiguration = [];
    const lengthOffset = this.plan.lenghtOffset - this.machine.defaultCutOffLength;

    this.ramConfigurationGlobal.forEach((config) => {
      const ram = { ...config };

      const isWithinRange = ram.position + ram.width >= lengthOffset &&
        ram.position <= lengthOffset + this.plan.length;

      if (isWithinRange) {
        ram.position = ram.position - lengthOffset;
        this.ramConfiguration.push(ram);
      }
    });
  }

  getWidth(width:number) {
    return this.ch.getWidth(width);
  }

  addEmptyGlueSet() {
    this.addEmptyBmgs.emit(true);
  }

  private RemoveBeamMaterialLine(bml: BeamMaterialLine, bm: BeamMaterial) {
    this.bmgsService.isDirty = true;
    bml.transportPackageDemand.QtySumUI = bml.transportPackageDemand.QtySumUI - 1;
    bm.beamMaterialLines = bm.beamMaterialLines.filter((obj) => obj !== bml);
    this.bmgs.layers.forEach((l) => {
      l.BeamMaterials = l.BeamMaterials.filter(
        (bm) => bm.beamMaterialLines.length > 0
      );
    });
    this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset).subscribe((calcLenght: number) => {
      bm.length = calcLenght;
      this.adjustBMLAndSetCords();
      this.updateGluePlan.emit();
      this.calculateUnits.emit({});
      this.copyService.activeSave = false;
    });
  }

  private getLayerIndex(bm: BeamMaterial): number {
    for (let i = 0; i < this.bmgs.layers.length; i++) {
      if (this.bmgs.layers[i].BeamMaterials.includes(bm)) {
        return i;
      }
    }
    return -1;
  }

  private RemoveBeamMaterial(bm: any) {
    this.bmgsService.isDirty = true;
    const layerValue = this.getLayerIndex(bm);
    // find BOL and reduce manualUnit
    this.bmgs.layers[layerValue].BeamMaterials = this.bmgs.layers[
      layerValue
    ].BeamMaterials.filter((obj) => obj !== bm); // TODO: filter is not working, object comparison is failing.
    // this.ReduceManualUnit(bm);
    if (this.bmgs.Template != null) {
      let hasTemplateBm = false;
      for (let i = 0; i < this.bmgs.layers[bm.layer].BeamMaterials.length; i++) {
        const xbm = this.bmgs.layers[bm.layer].BeamMaterials[i];
        for (let j = 0; j < xbm.beamMaterialLines.length; j++) {
          const xbml = xbm.beamMaterialLines[j];
          if (
            xbml.transportPackageDemand.BeamMaterialType !== null &&
            xbml.transportPackageDemand.BeamMaterialType.Name ===
            this.bmgs.Template.Name
          ) {
            hasTemplateBm = true;
          }
        }
      }
      if (!hasTemplateBm) {
        this.bmgs.RemoveTemplate(this.bmgs.Template);
      }
    }
    this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset).subscribe((x: number) => {
      bm.calculatedLength = x;
      this.adjustBMLAndSetCords();
      this.updateGluePlan.emit();
      this.calculateUnits.emit({});
      this.copyService.activeSave = false;
    });
  }

  private createBMLFromBOL(bol: Demandline): BeamMaterialLine {
    return new BeamMaterialLine(
      bol,
      bol.length,
      bol.BeamOrderRowID,
      bol.deafultNumberOfBeamsPerBeamMaterial,
      1,
      0,
      null,
      null,
      bol.transportPackageId,
      null,
      null,
      bol.transportPackageAllocation,
      null
    );
  }

  private createNewBM(demand: Demandline, width: number, hight: number): BeamMaterial {
    return new BeamMaterial(
      -1,
      0,
      -1,
      demand.NumberOfLaminas,
      false,
      1000, // Temp value will be recalculated
      undefined,
      demand.DeafultLamminaPlaneThicknes,
      demand.BeamMaterialType,
      demand.materialIndex,
      width,
      hight,
      null,
      Guid.newGuid(),
      false,
      0,
      demand.deafultNumberOfBeamsPerBeamMaterial > 1,
      this.machine.defaultCutOffLength,
      this.machine.cutWidth
    );
  }

  private AddLineToNewBM(
    newBmls: BeamMaterialLine[],
    width:number,
    hight:number,
    index:number
  ): boolean {
    const newBm = this.createNewBM(newBmls[0].transportPackageDemand, width, hight);

    let canAdd = true;

    newBmls.forEach(newBml => {
      const vr: ValidataionResult = this.Validate(
        this.bmgs,
        null,
        newBm,
        newBml,
        index
      );

      if (vr.valid) {
        newBm.AddNewLine(newBml);
      } else {
        canAdd = false;
        this.copyService.activeSave = false;
      }
    });

    if (canAdd) {
      this.beamMaterialService.getBeamMaterialLength(newBm, this.plan.machineId, this.plan.lenghtOffset).subscribe((calcLenght: number) => {
        newBm.length = calcLenght;
        this.bmgs.layers[index].BeamMaterials.push(newBm);
        this.updateGluePlan.emit();

        const totalWidth = this.getTotalWidthOfGP(newBm.beamMaterialLines[0].transportPackageDemand.LamminaPlaneWidth);
        if (totalWidth > this.machine.maxPressWidth) {
          this.notificationService.notifyErrorAppChanel('GlueSet.Total_Glue_Set_Width_Exceeded', 'GlueSet.BMGS_Edit_Error', { width: totalWidth, maxWidth: this.machine.maxPressWidth });
          this.bmgs.layers[index].BeamMaterials.pop();
          this.updateGluePlan.emit();
          canAdd = false;
        } else {
          this.calculateUnits.emit({});
        }

        this.adjustBMLAndSetCords();
        this.copyService.activeSave = false;
      });
    } else {
      this.copyService.activeSave = false;
    }
    return canAdd;
  }

  private addLineToNewBMForManualBeam(
    newBmls: BeamMaterialLine[],
    width:number,
    hight:number,
    index:number,
    length :number
  ): boolean {
    const newBm = this.createNewBMForManualBeamMaterial(newBmls[0].transportPackageDemand, width, hight);

    let canAdd = true;

    newBmls.forEach(newBml => {
      const vr: ValidataionResult = this.Validate(
        this.bmgs,
        null,
        newBm,
        newBml,
        index
      );

      if (vr.valid) {
        newBm.AddNewLine(newBml);
      } else {
        canAdd = false;
        this.copyService.activeSave = false;
      }
    });

    if (canAdd) {
      newBm.length = length;
      this.bmgs.layers[index].BeamMaterials.push(newBm);
      this.updateGluePlan.emit();
      const totalWidth = this.getTotalWidthOfGP(newBm.beamMaterialLines[0].transportPackageDemand.LamminaPlaneWidth);
      if (totalWidth > this.machine.maxPressWidth) {
        this.notificationService.notifyErrorAppChanel('GlueSet.Total_Glue_Set_Width_Exceeded', 'GlueSet.BMGS_Edit_Error', { width: totalWidth, maxWidth: this.machine.maxPressWidth });
        this.bmgs.layers[index].BeamMaterials.pop();
        this.updateGluePlan.emit();
        canAdd = false;
      } else {
        this.calculateUnits.emit({});
      }

      this.adjustBMLAndSetCords();
      this.copyService.activeSave = false;
    } else {
      this.copyService.activeSave = false;
    }
    return canAdd;
  }

  private createNewBMForManualBeamMaterial(demand: Demandline, width: number, hight: number): BeamMaterial {
    return new BeamMaterial(
      -1,
      0,
      -1,
      demand.NumberOfLaminas,
      false,
      1000, // Temp value will be recalculated
      undefined,
      demand.DeafultLamminaPlaneThicknes,
      new BeamMaterialType(null, null, 'Manual', '', '#160f71', '#160f71', 100, 10, false, false, true),
      demand.materialIndex,
      width,
      hight,
      null,
      Guid.newGuid(),
      false,
      0,
      demand.deafultNumberOfBeamsPerBeamMaterial > 1,
      this.machine.defaultCutOffLength,
      this.machine.cutWidth
    );
  }

  private getTotalWidthOfGP(newBmLaminaPlaneWidth: number) {
    const gluesetValue = this.gluelamStaticalMediatorService.gluesets;
    // Calculate total width for all gluesets
    const totalWidth = gluesetValue.reduce((acc, glueset, gluesetIndex) => {
      const gluesetWidth = this.calculateTotalWidth(newBmLaminaPlaneWidth, glueset.layers.length);
      return acc + (gluesetIndex > 0 ? this.gluingWidth : 0) + gluesetWidth;
    }, 0);
    return totalWidth;
  }

  private calculateTotalWidth(laminaPlaneWidth: number,noOfLayersInGS: number,): number {
    return laminaPlaneWidth * noOfLayersInGS + this.gluingWidth * (noOfLayersInGS - 1);
  }

  private AddLineToBM(
    newBmls: BeamMaterialLine[],
    bm: BeamMaterial,
    index:number
  ): boolean {
    let couldAdd = true;

    newBmls.forEach((newBML: BeamMaterialLine) => {
      newBML.BeamMaterialID = bm.beamMaterialID;
      newBML.IndexInCut = -1;

      const vr: ValidataionResult = this.Validate(
        this.bmgs,
        null,
        bm,
        newBML,
        index
      );

      if (vr.valid) {
        // if any BML is affected by template in current BM, then all BML in BM are affected by template.
        bm.AddNewLine(newBML);
      } else {
        couldAdd = false;
        this.copyService.activeSave = false;
      }
    });

    if (couldAdd) {
      this.beamMaterialService.getBeamMaterialLength(bm, this.plan.machineId, this.plan.lenghtOffset).subscribe((calcLenght: number) => {
        bm.length = calcLenght;
        this.adjustBMLAndSetCords();
        this.updateGluePlan.emit();
        this.copyService.activeSave = false;
      });
    } else {
      this.copyService.activeSave = false;
    }

    this.calculateUnits.emit({});

    return couldAdd;
  }

  private reorderBeamMaterialUpp(bm: BeamMaterial, bmgs: BeamMaterialGlueSet) {
    const layerValue = this.getLayerIndex(bm);

    const bmIndex = this.bmgs.layers[layerValue].BeamMaterials.indexOf(bm);
    if (bmIndex > 0) {
      const selectedBm = this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex,
        1
      );

      this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex - 1,
        0,
        selectedBm[0]
      );

      this.adjustBMLAndSetCords();
    }
  }

  private reorderBeamMaterialDown(bm: BeamMaterial, bmgs: BeamMaterialGlueSet) {
    const layerValue = this.getLayerIndex(bm);

    const bmIndex = this.bmgs.layers[layerValue].BeamMaterials.indexOf(bm);

    if (bmIndex < this.bmgs.layers[layerValue].BeamMaterials.length - 1) {
      const selectedBm = this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex,
        1
      );

      this.bmgs.layers[layerValue].BeamMaterials.splice(
        bmIndex + 1,
        0,
        selectedBm[0]
      );
    }

    this.adjustBMLAndSetCords();
  }

  private Validate(
    newBMGS: BeamMaterialGlueSet,
    suorceBM: BeamMaterial,
    newBM: BeamMaterial,
    newBML: BeamMaterialLine,
    toLayer: number
  ): ValidataionResult {
    const ret: Array<{ text: string, values: any }> = new Array<{ text: string, values: any }>();
    let heightLayer = 0;
    let numberOfLamminas = 0;
    let bmlInSameBM: boolean = false;
    let newHeight = 0;
    const bol = newBML.transportPackageDemand;

    // validation Different Dimension
    if (!this.lamminaDim) {
      if (this.isBMOfDifferentDimension(newBMGS, bol)) {
        ret.push({ text: 'GlueSet.BM_OF_DIFFERENT_DIM', values: { bm: newBMGS.layers[newBMGS.layers.length - 1].BeamMaterials[0].beamMaterialLines[0].transportPackageDemand.LamminaPlaneWidth } });
      }
    } else if (bol.BeamLamminaDimensionID !== this.lamminaDim?.beamLamminaDimensionID) {
      ret.push({ text: 'GlueSet.BM_OF_DIFFERENT_DIM', values: { bm: this.lamminaDim?.width } });
    }

    // Validate template
    if (bol.BeamMaterialType != null && newBMGS.Template != null) {
      if (bol.BeamMaterialType.Name !== newBMGS.Template.Name) {
        ret.push({ text: 'GlueSet.BMGS_CAN_NOT_ADD_MANY_TEMPLATES', values: {} });
      }
    }

    // validation ALl Units Planned
    if (
      suorceBM === null &&
      bol.QtyPlanned + bol.QtySumUI >= (bol.transportPackageAllocation ? bol.transportPackageAllocation.qty : bol.qty) + (bol.transportPackageAllocation ? bol.transportPackageAllocation.unitsExtra : bol.QtyReproduce)
    ) {
      ret.push({ text: 'GlueSet.ALL_UNITS_ROW_PLANNED', values: {} }); //    6                0                   1             >   7           0
    }

    // validation PLane Thickness
    if (bol.DeafultLamminaPlaneThicknes !== newBM.planedThickness) {
      ret.push({ text: 'GlueSet.BOR_PLANE_THICKNESS_DIFFERNET_FROM_BM', values: { bol: bol.DeafultLamminaPlaneThicknes, bm: newBM.planedThickness } });
    }

    if (newBMGS.beammaterials && newBMGS.beammaterials.length > 0 && newBMGS.beammaterials[0].planedThickness !== bol.DeafultLamminaPlaneThicknes) {
      ret.push({ text: 'GlueSet.BOR_PLANE_THICKNESS_DIFFERNET_FROM_BM', values: { bol: bol.DeafultLamminaPlaneThicknes, bm: newBMGS.beammaterials[0].planedThickness } });
    }

    // validation No Of Lamminas & Length & Hight
    if (suorceBM !== null) {
      numberOfLamminas = suorceBM.numberOfLamminas;
    } else {
      numberOfLamminas = bol.NumberOfLaminas;
    }

    const layerMaterials = newBMGS.layers[toLayer].BeamMaterials;
    layerMaterials.forEach((bm) => {
      heightLayer += bm.getHight();
      if (bm === newBM) {
        bmlInSameBM = true;
      }
    });

    if (!bmlInSameBM) {
      newHeight = heightLayer + (bol.NumberOfLaminas * bol.DeafultLamminaPlaneThicknes);
    }

    if (numberOfLamminas !== newBM.numberOfLamminas) {
      ret.push({ text: 'GlueSet.BOR_LAMMINAS_DIFFERNET_FROM_BM', values: {} });
    } else if (
      newBM.getLength() + newBML.length > this.machine.maxPressLength
    ) {
      const lenghtCalc = newBMGS.calcMaxLength() + newBML.length;

      ret.push(
        {
          text: 'GlueSet.Total_Glue_Set_Length_Exided',
          values: <any>{ lenght: lenghtCalc, maxLenght: this.machine.maxPressLength }
        });
    } else if (newHeight > this.machine.maxPressHight) {
      ret.push(
        {
          text: 'GlueSet.Total_Glue_Set_Hight_Exided',
          values: <any>{ height: newHeight, maxHeight: this.machine.maxPressHight }
        }
      );
    }

    // validation LengthOffset
    const lenghtOffsetValidationResult = this.gluelamStaticalMediatorService.validateLenghtOffset(this.plan.lenghtOffset, bol.length, this.machine);
    if (lenghtOffsetValidationResult.length > 0) {
      lenghtOffsetValidationResult.forEach((x) => {
        ret.push(x);
      });
    }

    // validation Species
    if (this.validateNoDifferentSpecies(newBMGS, newBML) > 1) {
      ret.push(
        {
          text: 'GlueSet.NOT_ALLOWED_TO_COMBINE_SPECIES',
          values: { }
        });
    }

    // notifying error
    ret.forEach((error) => {
      this.notificationService.notifyErrorAppChanel(
        error.text,
        'GlueSet.BMGS_Edit_Error',
        error.values
      );
    });

    // returning response
    const vr = new ValidataionResult();

    if (ret.length > 0) {
      vr.valid = false;
      vr.message = ret[0].text;
    } else {
      vr.valid = true;
    }
    return vr;
  }

  private isBMOfDifferentDimension(newBMGS: BeamMaterialGlueSet, bol: Demandline) {
    return newBMGS.layers.length > 0 && newBMGS.layers[newBMGS.layers.length - 1].BeamMaterials.length > 0 && bol.BeamLamminaDimensionID !== newBMGS.layers[newBMGS.layers.length - 1].BeamMaterials[0].beamMaterialLines[0].transportPackageDemand.BeamLamminaDimensionID;
  }

  private validateNoDifferentSpecies(newBMGS : BeamMaterialGlueSet,
    newBML: BeamMaterialLine) : number {
    const species:Array<string> = [];
    species.push(newBML.transportPackageDemand.species);

    newBMGS.layers.forEach((gs) => {
      gs.BeamMaterials.forEach((bm) => {
        bm.beamMaterialLines.forEach((bml) => {
          if (!species.includes(bml.transportPackageDemand.species)) {
            species.push(bml.transportPackageDemand.species);
          }
        });
      });
    });

    return species.length;
  }
}

@Component({
  selector: 'key-value-display-statical',
  styleUrls: ['./beam-material-gluset-statical.component.css'],
  template: `
    <span [ngClass]="{ 'info-pill': true, 'info-pill-prim': useBackground }">
      <b>{{ lable | translate }}:</b> {{ value | number }} <b>{{ unit }}</b>
    </span>
  `
})
export class KeyValueDisplayStaticalComponent {
  @Input() lable: string;
  @Input() value: number;
  @Input() unit: string;
  @Input() useBackground: boolean = false;

  constructor() { }
}
