import { Component, Inject, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Subject } from 'rxjs';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IGluPlanModalInput, IGluPlanModalResult } from '@app/core/models/glueplan-modal-input.model';
import { GluePlanFormComponent } from '../glue-plan-form/glue-plan-form.component';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { NgxSpinnerService } from 'ngx-spinner';

marker('App.Cancel');
marker('App.Add');
marker('App.Update');

@Component({
  selector: 'app-gluing-plan-modal-ng',
  templateUrl: './gluing-plan-modal-ng.component.html',
  styleUrls: ['./gluing-plan-modal-ng.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GluingPlanModalNgComponent extends DialogContentBase implements OnDestroy {
  ramConfiguration: IPressbedRamConfigurationDTO[] = [];
  defaultDimension: { width: string; beamLamminaDimensionID: number } = {
    width: 'Select All',
    beamLamminaDimensionID: null
  };
  isNew: boolean = false;

  private readonly destroy$: Subject<void> = new Subject<void>();
  @ViewChild(GluePlanFormComponent) childComponent: GluePlanFormComponent;

  constructor(
    private readonly spinner: NgxSpinnerService,
    public dialogRef: DialogRef,
    @Inject(DialogRef) public data: IGluPlanModalInput) {
    super(dialogRef);
    this.isNew = this.data.isNew;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSave(){
    this.spinner.show("gp-model-spinner");
    this.childComponent.addGluingPlan();
  }

  onSaveGlueplan(gp: GluingPlan){
    this.dialogRef.close(<IGluPlanModalResult>{ isGluePlanModified: true, gluePlan: gp });
    this.spinner.hide("gp-model-spinner");
  }

  closeDialog() {
    this.dialogRef.close(<IGluPlanModalResult>{ isGluePlanModified: false });
  }
}
