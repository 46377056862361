import { Component } from '@angular/core';

@Component({
  selector: 'app-block-production-glue-set',
  templateUrl: './block-production-glue-set.component.html',
  styleUrls: ['./block-production-glue-set.component.css']
})
export class BlockProductionGlueSetComponent {

}
