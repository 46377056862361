import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';

/*

Take note that Angular Material as a hole in this project. Is obsolete.

So any fixes should favior Kendo componets.

*/

@NgModule({
  imports: [
    MatDialogModule, // State ???
    MatButtonModule, // 95% Migrated dependant on Sidenav
    MatToolbarModule, // Only Dependant on Sidenav
    MatIconModule, // 95% Migrated dependant on Sidenav
    MatSidenavModule, // Dependant on Sidenav
    MatTreeModule, // Dependant on Sidenav
    MatListModule // Dependant on Sidenav
  ],
  exports: [
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatTreeModule,
    MatListModule
  ]
})
export class MaterialImportModule { }
