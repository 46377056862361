import { Component, Inject, Output, EventEmitter, LOCALE_ID, OnInit, OnDestroy, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth-service/auth.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { UserService, OptinexUserDTO, AppLocale, UserSiteDTO } from '@app/core/services/http-services/common/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountInfo } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { SVGIcon, lockIcon, unlockIcon, menuIcon, launchIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-user-settings',
  template: `
  <section class="user-profile">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
      <div class="col" kendoTooltip>
        <img [src]="userAvatar" (error)="avatarError()" alt="Avatar" [title]="localeId">
        <div style="flex-grow: 1;"></div>
        <!--<flag-icon class="pointer" *ngFor="let l of locales" (click)="setLang(l, true)" [country]="l.flag" [style]="{'grayscale(1)' : true }"></flag-icon>-->
        <div *ngFor="let l of locales">
        <span *ngIf="l.flag" (click)="setLang(l, true)"  [ngClass]="['fi', 'fi-' + l.flag ]"></span>
        </div>
        <kendo-svg-icon [size]="'xlarge'" [icon]="launchIcon" *ngIf="user?.isGlobalAdmin"  (click)="setLang(locales[4], true)"></kendo-svg-icon>
        <kendo-svg-icon [size]="'xlarge'" *ngIf="!locked" [icon]="lockIcon" aria-label="Unlock menu open." class="pointer" (click)="onPinMenu()" ></kendo-svg-icon>
        <kendo-svg-icon [size]="'xlarge'" *ngIf="locked" [icon]="unlockIcon" aria-label="Lock menu open." class="pointer" (click)="onPinMenu()"></kendo-svg-icon>
        <kendo-svg-icon [size]="'xlarge'" [icon]="menuIcon" aria-label="Menu" class="pointer" *ngIf="!user?.isMenuExpanded" (click)="onToggleMenu()"></kendo-svg-icon>
      </div>
      <h2 *ngIf="user">{{name}} ({{user?.tennatId }})</h2>
      <h3 *ngIf="user">{{account?.username}} ({{user?.userName}})</h3>
      <div class="row">
        <div style="flex-grow: 1;"></div>
      <kendo-loader themeColor="primary" *ngIf="!user">
      </kendo-loader>
      <div style="flex-grow: 1;"></div>
      </div>
      <app-select-tenant *ngIf="user?.isGlobalAdmin" [showLable]="true" [label]="'App.Tenant'" [selectedTenant]='user?.tennatId'></app-select-tenant>
  </section>
  `,
  styleUrls: ['user-settings.component.css']
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  user: OptinexUserDTO;
  userAvatar: SafeUrl;
  locales: AppLocale[];

  lockIcon: SVGIcon = lockIcon;
  unlockIcon: SVGIcon = unlockIcon;
  menuIcon: SVGIcon = menuIcon;
  launchIcon: SVGIcon = launchIcon;

  @Input() locked: boolean;
  @Input() expanded: boolean;

  get account(): AccountInfo {
    return this.authService.getAccount();
  }

  get name(): string {
    // eslint-disable-next-line dot-notation
    return `${this.account?.idTokenClaims['name']}`;
  }

  private userImgRetrived = false;

  private readonly _destroying$ = new Subject<void>();

  @Output() lockedMenu = new EventEmitter<boolean>();
  @Output() toggleExpanded = new EventEmitter<{}>();

  constructor(
    public authService: AuthService,
    private readonly translate: TranslateService,
    private readonly userService: UserService,
    @Inject(LOCALE_ID) public localeId: string,
    public intlService: IntlService,
    private readonly spinner: NgxSpinnerService,
    public router: Router,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly _sanitizer: DomSanitizer,
    private readonly localStorageService: LocalStorageService
  ) {
    // This language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    this.locales = userService.locales;
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  private getUserProfileImg() {
    if (!this.userImgRetrived) {
      this.userService.getPofileImage().subscribe(b => {
        const objectURL = URL.createObjectURL(b);
        this.userAvatar = this._sanitizer.bypassSecurityTrustResourceUrl(objectURL);
      });
      this.userImgRetrived = true;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getUserInfo() {
    if (!this.user) {
      this.userService.getUser().then(userInfo => {
        this.user = userInfo;
        this.locked = !userInfo.isMenuExpanded;
        this.setLang(this.userService.getLocaleByCode(userInfo.languageCode), false);
        this.getUserProfileImg();
        const userRoles = { [UserRoleEnum.Developer]: userInfo.isDeveloper, [UserRoleEnum.Planner]: userInfo.isPlanner, [UserRoleEnum.Operator]: userInfo.isOperator };
        this.localStorageService.setItem(LocalStorageService.userRoles, JSON.stringify(userRoles));
        this.setUserSite(userInfo.userSites);
      });
    }
  }

  avatarError(): void {
    this.userAvatar = 'assets/img/img_avatar.png';
  }

  setLang(locale: AppLocale, save: boolean = true): void {
    if (this.userService.getLocale().id !== locale.id) {
      this.changeLocale(locale, save);
    } else {
      console.info('Using Old Locale');
    }

    this.translate.use(locale.language).subscribe(() => {
      this.localStorageService.localeChanged();
    });
  }

  private changeLocale(locale: AppLocale, save: boolean) {
    console.info('Switching Locale');

    this.user.languageCode = locale.id.toLocaleLowerCase();

    if (save) {
      this.saveInfoWithLoadingIndicator();
    }

    this.userService.setLocale(locale.id);
  }

  onPinMenu() {
    console.log('userInfo', this.user.isMenuExpanded);
    this.locked = !this.locked;
    
    this.user.isMenuExpanded = this.locked;
    this.userService.saveUser(this.user).subscribe(x=>{
      this.lockedMenu.emit(this.locked);
    });
  }

  onToggleMenu() {
    this.toggleExpanded.emit();
  }

  saveInfoWithLoadingIndicator(): void {
    this.spinner.show();
    this.userService.saveUser(this.user).subscribe({
      next: () => { this.spinner.hide(); },
      error: () => { this.spinner.hide(); }
    });
  }

  setUserSite(userSites: UserSiteDTO[]) {
    // set site in local storage here
    if (userSites !== null) {
      // set userSites
      this.localStorageService.setItem(LocalStorageService.userSites, JSON.stringify(userSites));

      // set selected site id
      const selectedSiteId = this.localStorageService.getItem(LocalStorageService.selectedSiteId) ?? '';
      if (selectedSiteId === '' || !userSites.some(x => x.siteId === selectedSiteId)) {
        this.setDefaultSiteAsSelectedSite(userSites);
      }
    }
  }

  private setDefaultSiteAsSelectedSite(userSites: UserSiteDTO[]) {
    const defaultSite = this.userService.getDefaultSite(userSites);
    this.localStorageService.setItem(LocalStorageService.selectedSiteId, defaultSite.siteId);
  }
}
