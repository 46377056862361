<h1 translate>Operative.Device</h1>

<section class="app-button-row">
  <app-machine-selector-multi (selectedMachine)="onSelectMachine($event)"
                              style="width:80%;">
  </app-machine-selector-multi>
  <div style="flex-grow: 1;"></div>
  <button kendoButton class="k-mb-1" themeColor="primary" (click)="addDeviceRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>
<kendo-grid #deviceEditorGrid
  [data]="gridDeviceData"
  class="crud-grid"
  [pageSize]="state.take"
  [skip]="state.skip"
  [pageable]="true"
  [filterable]="true"
  [filter]="state.filter"
  [size]="'small'"
  (dataStateChange)="dataStateChange($event)"
  (edit)="editHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  >
    <kendo-grid-column field="deviceName" title="{{ 'Operative.DeviceName' | translate }}">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox [formControl]="formGroup.get('deviceName')"></kendo-textbox>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="machine.name"  title="{{ 'Operative.Machine' | translate }}" [filterable]="false">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <app-machine-selector-dropdown [showLabel]="false" [formControl]="formGroup.get('machineId')" *ngIf="!isEdit">
        </app-machine-selector-dropdown>
        <label *ngIf="isEdit"> {{ formGroup.get('machineName').value }} </label>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isActivated" title="{{ 'Operative.IsActivated' | translate }}" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem> {{ (dataItem.isActivated ? 'App.Yes' : 'App.No' ) | translate }} </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"> {{ (formGroup.get('isActivated').value ? 'App.Yes' : 'App.No') | translate }} </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}">
      <ng-template kendoGridCellTemplate  themeColor="primary" let-isNew="isNew" let-dataItem>
        <button kendoGridEditCommand  themeColor="primary" [primary]="true">{{ "App.Edit"  | translate}}</button>
        <button kendoGridRemoveCommand themeColor="error">{{ "App.Remove" | translate }}</button>
        <button kendoGridSaveCommand themeColor="primary" [disabled]="formGroup?.invalid"> {{ (isNew ? "App.Add" : "App.Update")  | translate}} </button>
        <button kendoGridCancelCommand  themeColor="error">{{ "App.Cancel"  | translate }}</button>
        <button kendoButton themeColor="primary" *ngIf="dataItem.deviceId > 0" [disabled]="!dataItem.isActivated" (click)="deactivate(dataItem)">{{ 'Operative.Deactivate' | translate }}</button>
      </ng-template>
    </kendo-grid-command-column>
</kendo-grid>


<app-confirmation-dialog
    *ngIf="deviceToRemove"
    (conformationDialogCloseEvent)="confirmRemove($event)">
</app-confirmation-dialog>
