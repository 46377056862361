import { NotificationComponent, NotificationDisplayComponent } from './notification/notification.component';

import { BrowserModule } from '@angular/platform-browser';
import { CardItemService } from './services/dashbord-card-item.service';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DashboardLayoutCardComponent } from './dashboard-layout/dashboard-card-component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashbordCardDirective } from './dashboard-layout/dashbord-card.directive';
import { DiagramComponent } from './diagram/diagram.component';
import { EntityQtyComponent } from './entity-qty/entity-qty.component';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialImportModule } from '@app/core/material-import.module';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PieDiagramComponent } from './pie-diagram/pie-diagram.component';
import { SimpleMetricComponent } from './simple-metric/simple-metric.component';
import { TranslateModule } from '@ngx-translate/core';
import localeSE from '@angular/common/locales/se-SE';
import { registerLocaleData } from '@angular/common';

// import localeAT from '@angular/common/locales/de-AT';






registerLocaleData(localeSE);
// registerLocaleData(localeAT);

@NgModule({
  declarations: [DiagramComponent, DashboardLayoutComponent, DashbordCardDirective, DashboardLayoutCardComponent, SimpleMetricComponent, PieDiagramComponent, NotificationDisplayComponent, NotificationComponent, EntityQtyComponent],
  imports: [
    DashboardRoutingModule,
    MaterialImportModule,
    KendoImportModule,
    TranslateModule,
    BrowserModule,
    ChartsModule,
    LayoutModule,
    NgxSpinnerModule
  ],
  providers: [
    CardItemService
  ]
})
export class DashboardModule { }
