import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BeamMaterialGlueSet } from '@app/core/models/beam-material-glue-set.model';
import { IGluPlanModalInput, IGluPlanModalResult } from '@app/core/models/glueplan-modal-input.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { GluePlanFormComponent } from '@app/modules/gluelam/controls/glue-plan-form/glue-plan-form.component';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.Next');
marker('App.Previous');

@Component({
  selector: 'app-block-production-wizard',
  templateUrl: './block-production-wizard.component.html',
  styleUrls: ['./block-production-wizard.component.css']
})
export class BlockProductionWizardComponent extends DialogContentBase implements OnInit{
  selectedTabIndex: number = -1;
  glueSets: BeamMaterialGlueSet[] = [];
  isGluePlanModified: boolean = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  @ViewChild(GluePlanFormComponent) childComponent: GluePlanFormComponent;
  constructor(@Inject(DialogRef) public data:IGluPlanModalInput,
  private readonly bmgsService: GlueSetService,
  private readonly spinner: NgxSpinnerService,
  private readonly dialogRef: DialogRef){
    super(dialogRef);
  }

  ngOnInit(): void {
    this.getGlueSetsByGluePlanId(this.data.gluingPlan.gluingPlanID);
  }

  getGlueSetsByGluePlanId(id: number) {
    this.spinner.show("block-production-spinner");
    this.bmgsService.getGlueSetByGluingPlanId(id).pipe(takeUntil(this.destroy$)).subscribe((glueSets: BeamMaterialGlueSet[]) => {
      this.spinner.hide("block-production-spinner");
      if (glueSets && glueSets.length > 0) {
        this.glueSets = glueSets;
      }else{
        this.glueSets=[];
      }
    });
  }

  isDisabled(): boolean{
    if(this.childComponent)
       return this.childComponent.gluingPlanForm.get('glueSetStateId').value <= GlueSetState.PLANNED;
    else{
     return false;
    }
  }

  isNextDisabled(): boolean{
    return this.isDisabled() || this.glueSets?.length < 0 || this.selectedTabIndex == this.glueSets?.length - 1
  }

  onSelect(e: SelectEvent): void {
    this.selectedTabIndex = e.index - 1;
  }

  onSaveGlueplan(plan: GluingPlan){
    this.isGluePlanModified = true;
    this.data.gluingPlan = {...plan}
    this.spinner.hide("block-production-spinner");
    if(this.data.gluingPlan.glueSetStateId <= GlueSetState.PLANNED)
    {
      this.closeDialog();
    }
  }

  saveGlueplan(){
    this.spinner.show("block-production-spinner");
    this.childComponent.addGluingPlan();
  }

  closeDialog(){
    this.dialogRef.close(<IGluPlanModalResult>{ isGluePlanModified: this.isGluePlanModified });
  }

  previous(){
    this.selectedTabIndex = this.selectedTabIndex - 1;
  }

  next(){
    this.selectedTabIndex = this.selectedTabIndex + 1;
  }
}
