<ngx-spinner [fullScreen]="true" type="ball-scale-multiple" name="block-production-spinner"></ngx-spinner>
<div class="container">
  <kendo-tabstrip (tabSelect)="onSelect($event)">
    <kendo-tabstrip-tab [selected]="selectedTabIndex === -1">
      <ng-template kendoTabTitle>
        <h4> {{ "GluePlan.GP" | translate }} - {{data.gluingPlan.gluingPlanID}}</h4>
      </ng-template>
      <ng-template kendoTabContent>
        <app-glue-plan-form [data]="data" (onSaveGlueplan)="onSaveGlueplan($event)"></app-glue-plan-form>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab *ngFor="let glueset of glueSets; index as i;" [selected]="i === selectedTabIndex" title="Tab 2 (disabled)"
      [disabled]="isDisabled()">
      <ng-template kendoTabTitle>
        <h4> {{ "GluePlan.GS" | translate }} - {{glueset.beamMaterialGlueSetID}}</h4>
      </ng-template>
      <ng-template kendoTabContent>
        <app-block-production-glue-set></app-block-production-glue-set>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<kendo-dialog-actions kendo-dialog-action>
  <div class="k-d-flex k-justify-content-end">
    <button class="k-mr-1" kendoButton themeColor="error" (click)="closeDialog()" translate>
      {{'App.Close' | translate}}
    </button>
    <button class="k-mr-1" kendoButton themeColor="primary" [disabled]="!childComponent?.gluingPlanForm?.valid"
      (click)="saveGlueplan()" translate>
      {{'App.Update' | translate}}
    </button>
    <button class="k-mr-1" (click)="previous()" [disabled]="this.selectedTabIndex == -1" kendoButton themeColor="primary" translate>
      {{ 'App.Previous' | translate}}
    </button>
    <button class="k-mr-1" (click)="next()" [disabled]="isNextDisabled()" kendoButton themeColor="primary" translate>
      {{ 'App.Next' | translate}}
    </button>
  </div>
</kendo-dialog-actions>
