import { Component, ViewChild, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { OrderView } from '@app/modules/gluelam/controls/order-view/order-view.component';
import { BeamMaterialGlueSet, BeamMaterialGlueSetState } from '@app/core/models/beam-material-glue-set.model';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { StaticalOptimazationOrderEditor } from '@app/modules/gluelam/controls/statical-optimization-order/statical-optimization-order.component';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { Observable, Subject, forkJoin } from 'rxjs';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService, WindowState, DialogRef } from '@progress/kendo-angular-dialog';
import { Demandline } from '@app/core/models/demand-line.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { UpdateSumUIQtyDTO, DeamndService, UpdateSumUIQtyForStaticalDTO } from '@app/core/services/http-services/gluelam/demand.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { GluingPlanModalNgComponent } from '@app/modules/gluelam/controls/gluing-plan-modal-ng/gluing-plan-modal-ng.component';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { OptiInputModel, StaticalOptimizationResponce } from '@app/core/models/statical-optimization-order.model';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { takeUntil } from 'rxjs/operators';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { IGluPlanModalInput, IGluPlanModalResult } from '@app/core/models/glueplan-modal-input.model';
import { BlockProductionService } from '@app/core/services/http-services/gluelam/block-production-service';
import { TranslateService } from '@ngx-translate/core';

marker('GlueSet.GLUSET_SAVE_ERROR');
marker('GlueSet.GLUSET_UPDATED');
marker('GlueSet.BMGS_NOT_Removed');
marker('GlueSet.GLUSET_SORTED_INFO');
marker('GluePlan.GluePlanDeleted');
marker('GluePlan.GluePlanSuccessMessage');
marker('GluePlan.GluePlanAdded');
marker('GluePlan.GluePlanUpdated');
marker('GluePlan.GluePlanConfirmReleased');

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'glulam-statical-planner',
  templateUrl: './statical-planner.component.html',
  styleUrls: ['./statical-planner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "d-flex-col-component"
  }
})
export class StaticalPlannerComponent implements OnInit, OnDestroy {
  @ViewChild(OrderView) orderView: OrderView;

  uiQty: UpdateSumUIQtyDTO[] = []; // uiQty to pass data to the order view
  updateList: UpdateSumUIQtyForStaticalDTO[] = []; // Using this for local calculations
  windowState: WindowState = 'default';
  opened = true;
  isLeftMajor = false;
  updatedTotalBeamCount = 0;
  selectedMachineId: number = null;
  selectedMachine: IMachineDTO = null;
  gsCols = 5;
  selectedPlan: GluingPlan;
  gluPlans: GluingPlan[] = [];
  _BeamMaterialGlueSetSuggestions: BeamMaterialGlueSet[] = [];
  states: BeamMaterialGlueSetState[] = [];
  selectedState: BeamMaterialGlueSetState;
  refreshPressbedView: Subject<GluingPlan> = new Subject<GluingPlan>();
  isExpanded: boolean = true;
  selectedGluplan: GluingPlan;
  isRefreshOrderView: Subject<boolean> = new Subject<boolean>();
  //All beam ordered lines. These are sent to order-row-filter view, which filters them and then set to app-order-view.
  LatestDeploymentDate: Date;
  LamminaDimms: BeamLamminaDimension[] = new Array<BeamLamminaDimension>();
  ready = false;
  //selected Lammina dimentions
  selectedLamminaDim: BeamLamminaDimension = null;
  selectedBeamOrderLines: Demandline[] = [];
  interval: any;
  release: {
    itemToReleased: boolean;
    glueplanIds: number[];
  };
  ramConfiguration: IPressbedRamConfigurationDTO[];

  private isOperator: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  private noOfHoursToAdd = 4;

  constructor(
    private  notificationService: AppNotificationService,
    private readonly bmgsService: GlueSetService,
    private readonly dimensionService: DimensionService,
    private readonly beamOrderRowService: DeamndService,
    private readonly conf: ConfigurationService,
    private readonly gluingPlanService: GluePlanService,
    private readonly spinner: NgxSpinnerService,
    public readonly gluelamStaticalMediatorService: GluelamStaticalMediatorService,
    private readonly blockProductionService: BlockProductionService,
    private readonly translateService: TranslateService,
    private dialogService: DialogService
  ) {
    this.resetGlueplanRelease();
  }

  ngOnInit(): void {
    this.conf.load().then(() => this.setUpSubscriptions());

    this.gluelamStaticalMediatorService
      .glueplanOffsetChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((gluePlan: GluingPlan) => {
        this.updateOffset(gluePlan);
      });

    this.gluelamStaticalMediatorService
      .glueSetUpdate
      .pipe(takeUntil(this.destroy$))
      .subscribe((plan: GluingPlan) => {
        if (plan != null) {
          const gluplanIndex = this.gluPlans.findIndex(x => x.gluingPlanID === plan?.gluingPlanID);
          if (gluplanIndex !== -1) {
            this.gluPlans[gluplanIndex] = plan;
            this.refreshPressbedView.next(plan);
          }
        }
      });
  }

  updateOffset(gluePlan: GluingPlan) {
    if (gluePlan?.gluingPlanID) {
      const gluplan = this.gluPlans.find(plan => plan.gluingPlanID === Number(gluePlan.gluingPlanID));
      gluplan.lenghtOffset = gluePlan.lenghtOffset; // Corrected property name
      gluplan.beamLaminaDimentionId = gluplan.beamLaminaDimentionId === 0 ? null : gluplan.beamLaminaDimentionId;

      this.gluingPlanService
        .updateGluingPlan(gluplan)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resp) => {
          const index = this.gluPlans.findIndex(plan => plan.gluingPlanID === resp.gluingPlanID);
          if (index !== -1) {
            resp.plannedExecutionStartDate = new Date(resp.plannedExecutionStartDate);
            resp.plannedExecutionEndDate = new Date(resp.plannedExecutionEndDate);
            this.gluPlans[index] = resp;
          }
          this.refreshPressbedView.next(resp);
          this.notificationService.notifySucsessAppChanel('GluePlan.GluePlanUpdated');
        });
    }
  }

  relseGlueplans(event: number[]) {
    this.release.itemToReleased = true;
    this.release.glueplanIds = event;
  }

  confirmGlueplansRelease(isRelease: boolean) {
    if (isRelease) {
      this.spinner.show();
      const obserables: Observable<GluingPlan>[] = [];
      const plans = this.gluPlans.filter(x => {
        return this.release.glueplanIds.includes(x.gluingPlanID);
      });
      plans.forEach((plan) => {
        plan.glueSetStateId = GlueSetState.RELEASED;
        obserables.push(this.gluingPlanService.updateGluingPlan(plan));
      });
      forkJoin(obserables).pipe(takeUntil(this.destroy$)).subscribe((result: GluingPlan[]) => {
        this.spinner.hide();
        this.getGluingPlans();
        this.refreshOrderView();
        result.forEach((plan, index) => {
          this.notificationService.notifySucsessAppChanel('GluePlan.GluePlanUpdated');
        });
      });
    }
    this.resetGlueplanRelease();
  }

  get BeamMaterialGlueSetSuggestions(): BeamMaterialGlueSet[] {
    if (this.selectedState && this.selectedState.name !== 'All') {
      return this._BeamMaterialGlueSetSuggestions.filter((x) => {
        return x.beamMaterialGlueSetState.glueSetStateId === this.selectedState.glueSetStateId;
      });
    }
    return this._BeamMaterialGlueSetSuggestions;
  }

  openClose(isOpened: boolean): void {
    this.opened = isOpened;
  }

  set BeamMaterialGlueSetSuggestions(value: BeamMaterialGlueSet[]) {
    this._BeamMaterialGlueSetSuggestions = value;
  }

  onCalcTotalsPerBeamOrderRow(glueSets: BeamMaterialGlueSet[]) {
    /* insted of creating updateList everytime, update the list with new vlaues so that we can preserve the data for other plans as well */
    this.updateList = Array.from(this.updateList);
    this.updateList.forEach((x) => {
      x.isInitialized = false;
    });

    /* If no GLuesets / all gluesets deleted OR if any glueset with state greater than equal to planned OR has all te beammaterials removed from layer */
    if (glueSets.length <= 0 || glueSets.filter((g) => g.layers.length <= 0 || g.beamMaterialGlueSetState.glueSetStateId >= GlueSetState.PLANNED).length > 0) {
      const indexes = [];

      this.updateList.filter((g) => g.gluePlanId === this.selectedPlan.gluingPlanID).forEach((x, index) => indexes.push(index));

      indexes.forEach((x) => {
        this.updateList[x] = { ...this.updateList[x], newQty: 0 };
      });
    } else {
      glueSets
        .filter((g) => g.beamMaterialGlueSetState.glueSetStateId < GlueSetState.PLANNED && g.layers.length > 0)
        .forEach((bmgs) => {
          bmgs.layers.forEach((l) => {
            l.BeamMaterials.forEach((bm) => {
              bm.beamMaterialLines.forEach((bml) => {
                const u = this.updateList.findIndex(
                  (g) => g.demandId === bml.beamOrderRowID && bml.transportPackageId === g.transportPackageId && g.gluePlanId === this.selectedPlan.gluingPlanID
                );

                if (u >= 0) {
                  if (!this.updateList[u].isInitialized) {
                    this.updateList[u] = { ...this.updateList[u], newQty: bml.NumberOfBeams * bmgs.performedTimes };
                    this.updateList[u].isInitialized = true;
                  } else {
                    this.updateList[u] = { ...this.updateList[u], newQty: this.updateList[u].newQty + bml.NumberOfBeams * bmgs.performedTimes };
                  }
                } else {
                  this.updateList.push(<UpdateSumUIQtyForStaticalDTO>{
                    demandId: bml.beamOrderRowID,
                    transportPackageId: bml.transportPackageId,
                    newQty: bml.NumberOfBeams * bmgs.performedTimes,
                    gluePlanId: this.selectedPlan.gluingPlanID,
                    isInitialized: true
                  });
                }
              });
            });
          });
        });
    }
    this.uiQty = [...this.updateList];
  }

  resizeGrid(e: boolean) {
    this.isLeftMajor = e;
    this.gsCols = e ? 4 : 5;
  }

  onMachineSelected(m: IMachineDTO) {
    this.selectedMachine = m;
    if (m !== undefined) {
      this.updateSelection(null);
      this.selectedMachineId = m.machineId;
      this.ramConfiguration = m.pressbedRamConfigurations;
      this.getGluingPlans();

      this.gluelamStaticalMediatorService.notifyGlueplanRamConfigurationChange(this.ramConfiguration);
    }
  }

  setUpSubscriptions() {
    this.beamOrderRowService.selectedOrderRows.pipe(takeUntil(this.destroy$)).subscribe((rows) => {
      this.selectedBeamOrderLines = rows;
    });

    this.gluingPlanService.selectedPlan.pipe(takeUntil(this.destroy$)).subscribe((plan: GluingPlan) => {
      this.spinner.hide();
      this.selectedPlan = plan;
      this.BeamMaterialGlueSetSuggestions = [];
      this.selectedLamminaDim = this.LamminaDimms.find(
        (d) => d.beamLamminaDimensionID === this.selectedPlan?.beamLaminaDimentionId
      );
    });
  }

  getGluingPlans() {
    this.gluPlans = [];
    this.dimensionService.getBeamLamminaDimentions().pipe(takeUntil(this.destroy$)).subscribe((dim) => {
      this.LamminaDimms.push(...dim);
      const findIDs = this.isOperator
        ? [GlueSetState.PLANNED, GlueSetState.SENT_BY_PLANNER, GlueSetState.RECIVED_BY_PROD_SYSTEM, GlueSetState.FAIL_TO_RECEIVE_BY_PROD_SYSTEM, GlueSetState.STARTED, GlueSetState.PAUSED]
        : [GlueSetState.GENERATED, GlueSetState.PLANNED, GlueSetState.SENT_BY_PLANNER, GlueSetState.RECIVED_BY_PROD_SYSTEM, GlueSetState.FAIL_TO_RECEIVE_BY_PROD_SYSTEM];
      this.gluingPlanService.getGluingPlans(findIDs, false, true).pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result && result.data.length > 0) {
          this.gluPlans = [];
          if (this.selectedMachine.machineId === undefined) {
            this.gluPlans.push(...result.data);
          } else {
            this.gluPlans.push(...result.data.filter((g) => g.machineId === this.selectedMachine.machineId));
          }
          this.gluPlans.sort((a, b) => b.gluingPlanID - a.gluingPlanID);
          this.gluPlans.forEach((x) => {
            x.dimension = this.LamminaDimms.filter((y) => y.beamLamminaDimensionID === x.beamLaminaDimentionId)[0];
          });
        }
        this.refreshPressbedView.next(null);
      });
    });
  }

  getGluingPlanById(id: number) {
    this.gluingPlanService.getGluingPlan(id).pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        const index = this.gluPlans.findIndex(x => x.gluingPlanID === id);
        if (index >= 0) {
          this.gluPlans[index] = result;
        } else {
          this.gluPlans.unshift(result);
        }
        this.GetSavedGlueSetsForGluingPlan(id);
      }

      this.refreshPressbedView.next(null);
    });
  }

  removeOrder(order: any) {
    this.beamOrderRowService.unselectOrderRow(order);
  }

  addGluingPlan(plan: GluingPlan = null) {
    const isNewFromOptimizer = plan?.gluingPlanID === 0;
    if (isNewFromOptimizer) {
      plan = <GluingPlan>{
        name: plan.name,
        gluingPlanID: 0,
        glueSetStateId: 1,
        plannedExecutionStartDate: plan.plannedExecutionStartDate,
        plannedExecutionEndDate: plan.plannedExecutionEndDate,
        lenghtOffset: plan.lenghtOffset,
        machineId: plan.machineId,
        beamLaminaDimentionId: plan.beamLaminaDimentionId,
        length: plan.length,
        gluePlanIndex: plan.gluePlanIndex
      };
    } else{
      plan = <GluingPlan>{
        name: '',
        gluingPlanID: 0,
        glueSetStateId: 1,
        plannedExecutionStartDate: new Date(),
        plannedExecutionEndDate: new Date(new Date().setHours(new Date().getHours() + this.noOfHoursToAdd))
      };
    }

    this.selectedGluplan = plan;

    const dialogRef = this.openDialog(true, plan);
    dialogRef.result.subscribe((gluePlanResult: IGluPlanModalResult)  => {
      if (gluePlanResult.isGluePlanModified) {

          this.notificationService.notifySucsessAppChanel('GluePlan.GluePlanAdded');
          if (isNewFromOptimizer) {
            const gluplanIndex = this.gluPlans.findIndex(x => x.gluePlanIndex === this.selectedGluplan.gluePlanIndex);
            this.gluPlans[gluplanIndex].gluingPlanID = gluePlanResult.gluePlan.gluingPlanID;
            this.gluPlans[gluplanIndex].glueSets.forEach(element => {
              element.save = true;
            });
          } else {
            this.getGluingPlanById(gluePlanResult.gluePlan.gluingPlanID);
          }
      }
    });
  }

  editGluingPlanBlockProduction(plan: GluingPlan = null) {
      var dialogRef = this.blockProductionService.openDialog(plan);
      dialogRef.result.subscribe((gluePlanResult: IGluPlanModalResult)  => {
        this.getGluingPlanById(gluePlanResult.gluePlan.gluingPlanID);
      });
  }

  deleteGluingPlan(plan: GluingPlan) {
    this.gluingPlanService.deleteGluingPlan(plan.gluingPlanID).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.removeGluePlanFromList(plan.gluingPlanID);
      this.selectedPlan = this.gluPlans[0];
      this.gluingPlanService.setSelectedPlan(this.gluPlans[0]);
      this.refreshOrderView();
      this.notificationService.notifySucsessAppChanel('GluePlan.GluePlanDeleted');
    });
  }

  updateSelection(gluingPlan: GluingPlan | undefined) {
    this.selectedPlan = gluingPlan;
    this.gluingPlanService.setSelectedPlan(gluingPlan);
  }

  up(id: number, gluingPlan: GluingPlan) {
    if (gluingPlan.glueSetStateId === 3 || gluingPlan.glueSetStateId === 4 || gluingPlan.glueSetStateId === 5) {
      this.gluPlans = this.gluPlans.filter((gp) => gp.gluingPlanID !== id);
    } else {
      const plan = this.gluPlans.find((gp) => gp.gluingPlanID === id);

      plan.beamLaminaDimentionId = gluingPlan.beamLaminaDimentionId;
      plan.glueSetStateId = gluingPlan.glueSetStateId;
      plan.name = gluingPlan.name;
      plan.plannedExecutionStartDate = gluingPlan.plannedExecutionStartDate;
      plan.plannedExecutionEndDate = gluingPlan.plannedExecutionEndDate;
      plan.instruction = gluingPlan.instruction;
      plan.machineId = gluingPlan.machineId;
    }
  }

  openOptimizationEditor() {
    this.dimensionService.getBeamLamminaDimention(this.selectedBeamOrderLines[0].BeamLamminaDimensionID).pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.selectedLamminaDim = x;
    });
    const dialogRef = this.dialogService.open({
      content: StaticalOptimazationOrderEditor,
      height: '1000px',
      width: '700px',
    });

    const content = dialogRef.content.instance as StaticalOptimazationOrderEditor;
    content.input = <OptiInputModel>{
      BeamLamminaDimension: this.selectedLamminaDim,
      BeamOrderLines: this.selectedBeamOrderLines,
      machine: this.selectedMachine
    };

    dialogRef.result.pipe(takeUntil(this.destroy$)).subscribe((result: StaticalOptimizationResponce) => {
      if (result && result.gluePlans.length > 0) {
        this.gluPlans.push(...result.gluePlans);
        this.gluelamStaticalMediatorService.notifyOnIsGlueplanSaveAllChangesValidChanged(true);
      }
    });
  }

  refreshOrderView() {
    this.isRefreshOrderView.next(true);
  }

  saveAllGluingPlans() {
    this.gluelamStaticalMediatorService.notifyOnGluePlanSave();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private GetSavedGlueSetsForGluingPlan(id: number) {
    this.spinner.show();
    this.bmgsService.getGlueSetByGluingPlanId(id).pipe(takeUntil(this.destroy$)).subscribe((glueSets) => {
      if (glueSets && glueSets.length > 0) {
        this.BeamMaterialGlueSetSuggestions = glueSets;
        this.populateState();

        this.refreshOrderView();
        this.onCalcTotalsPerBeamOrderRow(glueSets);
      }
      this.spinner.hide();
    });
  }

  private populateState() {
    this.states = [];
    const allState = new BeamMaterialGlueSetState(-1, 'All', 'All PlaceHolder', false, []);
    this.states.push(allState);

    this.BeamMaterialGlueSetSuggestions.forEach((bmgs) => {
      if (!this.states.find((x) => x.name === bmgs.beamMaterialGlueSetState.name)) {
        this.states.push(bmgs.beamMaterialGlueSetState);
      }
    });

    this.selectedState = allState;
  }

  private resetGlueplanRelease() {
    this.release = {
      itemToReleased: false,
      glueplanIds: []
    };
  }

  private removeGluePlanFromList(gluingPlanID: number) {
    this.gluPlans.splice(this.gluPlans.findIndex((x) => x.gluingPlanID === gluingPlanID), 1);
  }

  private openDialog(isNew: boolean, plan: GluingPlan): DialogRef {
    const dialogRef = this.dialogService.open({
      content: GluingPlanModalNgComponent,
      title: isNew ? this.translateService.instant('GluePlan.AddGluingPlan') : this.translateService.instant('GluePlan.UpdateGluePlan'),
      width: 600
    });

    const content = dialogRef.content.instance as GluingPlanModalNgComponent;

    content.data = <IGluPlanModalInput>{
      isNew,
      gluingPlan: { ...plan },
      isContinuousPressGroup: false,
      isStaticalPressGroup: true,
      isStaticalScheduler: false
    };

    return dialogRef;
  }

}
