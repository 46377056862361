import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { IGluPlanModalInput } from '@app/core/models/glueplan-modal-input.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetState, GlueSetStateService, IBeamMaterialGlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { CreateGluingPlanForm } from '@app/core/models/forms/gluelam/create-gluing-plan-form.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('GluePlan.COMPLETED');
marker('GluePlan.GENERATED');
marker('GluePlan.PLANNED');
marker('GluePlan.STARTED');
marker('GluePlan.SENT_BY_PLANNER');
marker('GluePlan.RECIVED_BY_PROD_SYSTEM');
marker('GluePlan.FAIL_TO_RECEIVE_BY_PROD_SYSTEM');
marker('GluePlan.PAUSED');
marker('GluePlan.CANCELED');
marker('GluePlan.RELEASED');
marker('App.ErrorEndDateLessThenStartDate');

@Component({
  selector: 'app-glue-plan-form',
  templateUrl: './glue-plan-form.component.html',
  styleUrls: ['./glue-plan-form.component.css']
})
export class GluePlanFormComponent implements OnInit {
  @Input() data: IGluPlanModalInput;
  @Output() onSaveGlueplan = new EventEmitter<GluingPlan>();

  gluingPlanForm: FormGroup = new FormGroup({});
  states:IBeamMaterialGlueSetState[] = [];
  selectedMachine : IMachineDTO = null;
  dimensions:BeamLamminaDimension[] = [];

  private destroy$: Subject<void> = new Subject<void>();
  private readonly noOfHoursToAdd = 4;

  constructor(
    private readonly notificationService: AppNotificationService,
    private readonly dimensionService: DimensionService,
    private readonly glueSetStateService: GlueSetStateService,
    private readonly gluePlanService :GluePlanService,
    private readonly gluelamStaticalMediatorService: GluelamStaticalMediatorService
  ){}


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    forkJoin({
      dimentions: this.dimensionService.getBeamLamminaDimentions(),
      gluesetStates: this.glueSetStateService.getGlusetStatesForPlan(this.data.gluingPlan.gluingPlanID)
    }).pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.dimensions = result.dimentions;
      this.states = result.gluesetStates.filter(s => s.isValidForGluePlan && s.canChangeToState);
    });

    this.gluingPlanForm = new FormGroup<CreateGluingPlanForm>({
      gluingPlanID: new FormControl<number>(this.data.gluingPlan.gluingPlanID),
      name: new FormControl<string>(this.data.gluingPlan.name, [Validators.required, Validators.minLength(3)]),
      instruction: new FormControl<string>(this.data.gluingPlan.instruction),
      plannedExecutionStartDate: new FormControl<Date>(this.data.gluingPlan?.plannedExecutionStartDate ? new Date(this.data.gluingPlan?.plannedExecutionStartDate) : new Date(), [Validators.required]),
      plannedExecutionEndDate: new FormControl<Date>(this.data.gluingPlan?.plannedExecutionEndDate ? new Date(this.data.gluingPlan?.plannedExecutionEndDate) : new Date(new Date().setHours(new Date().getHours() + this.noOfHoursToAdd)), [Validators.required]),
      glueSetStateId: new FormControl<number>(this.data.gluingPlan.glueSetStateId, [Validators.required]),
      machineId: new FormControl<number>(this.data.gluingPlan.machineId, [Validators.required]),
      lenghtOffset: new FormControl<number>(this.data.gluingPlan.lenghtOffset),
      length: new FormControl<number>(this.data.gluingPlan.length),
      beamLaminaDimentionId: new FormControl<number>(this.data.gluingPlan.beamLaminaDimentionId)
    });


    if (this.data.isStaticalPressGroup) {
      this.gluingPlanForm.get('lenghtOffset').setValidators([Validators.required, this.lenghtOffsetValidator({ ...this.data.gluingPlan })]);
      this.gluingPlanForm.get('beamLaminaDimentionId').setValue(null);
      this.gluingPlanForm.get('length').disable();
      if (this.data.isStaticalScheduler && this.data.gluingPlan.glueSetStateId !== GlueSetState.RELEASED) {
        this.gluingPlanForm.disable();
        this.gluingPlanForm.get('glueSetStateId').enable();
      }
    } else {
      this.gluingPlanForm.get('beamLaminaDimentionId').setValidators([Validators.required]);
    }

    if (this.gluePlanService.isDisabledEndDate(this.data.gluingPlan)) {
      this.gluingPlanForm.get('plannedExecutionEndDate').disable();
    }

    this.gluingPlanForm.get('plannedExecutionStartDate').valueChanges.subscribe(value => {
      if (value) {
        // add 4 hours to start date time
        // calculates new end date time
        const calculatedEndDate = new Date(new Date(value).setHours(new Date(value).getHours() + this.noOfHoursToAdd));
        this.gluingPlanForm.get('plannedExecutionEndDate').setValue(calculatedEndDate);
      }
    });
  }

  isRAMConfigurationAbsent() {
    return !this.selectedMachine?.machineId || !this.selectedMachine?.pressbedRamConfigurations || this.selectedMachine?.pressbedRamConfigurations.length === 0;
  }

  onEndDateTimeChange() {
    let startDateTime = this.gluingPlanForm.get('plannedExecutionStartDate').value;
    let endDateTime = this.gluingPlanForm.get('plannedExecutionEndDate').value;

    if (startDateTime && endDateTime) {
      startDateTime = new Date(startDateTime);
      endDateTime = new Date(endDateTime);

      if (endDateTime <= startDateTime) {
        this.notificationService.notifyErrorAppChanel('App.ErrorEndDateLessThenStartDate');
        this.gluingPlanForm.get('plannedExecutionEndDate').patchValue(null);
      }
    }
  }

  lenghtOffsetValidator(gluePlan: GluingPlan): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      gluePlan.lenghtOffset = control.value;
      const result = this.gluelamStaticalMediatorService.validateLenghtOffset(gluePlan.lenghtOffset, gluePlan.length, this.selectedMachine);
      return result.length > 0 ? { exeedLenght: true } : null;
    };
  }

  async addGluingPlan() {
    if (!this.gluingPlanForm.valid) {
      return;
    }

    if (this.data.isNew) {
      this.gluePlanService.addGluingPlan(this.gluingPlanForm.getRawValue())
        .pipe(takeUntil(this.destroy$))
        .subscribe((gp: GluingPlan) => {
          this.processGluePlan(gp);
        });
    } else {
       this.gluePlanService.updateGluingPlan(this.gluingPlanForm.getRawValue())
        .pipe(takeUntil(this.destroy$))
        .subscribe((gp: GluingPlan) => {
          this.processGluePlan(gp);
        });
    }
  }

  processGluePlan(gp: GluingPlan) {
    gp.plannedExecutionStartDate = new Date(gp?.plannedExecutionStartDate);
    gp.plannedExecutionEndDate = new Date(gp?.plannedExecutionEndDate);
    this.onSaveGlueplan.emit(gp);
  }
}
