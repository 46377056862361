<form class="formcls" [formGroup]="gluingPlanForm">
  <kendo-formfield>
    <kendo-label text="{{ 'GluePlan.Name' | translate }}"></kendo-label>
    <kendo-textbox formControlName="name">
    </kendo-textbox>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label text="{{ 'GluePlan.Instruction' | translate }}"></kendo-label>
    <kendo-textarea formControlName="instruction" [rows]="6">
    </kendo-textarea>
  </kendo-formfield>
  <app-machine-selector-dropdown [isStaticalPressGroup]="data?.isStaticalPressGroup"
    [isContinuousPressGroup]="data?.isContinuousPressGroup" formControlName="machineId"
    (selectedMachine)="selectedMachine = $event">
  </app-machine-selector-dropdown>
  <kendo-formfield *ngIf="data?.isStaticalPressGroup">
    <kendo-label text="{{ 'GluePlan.EdgeOffset' | translate }}">
    </kendo-label>
    <kendo-numerictextbox *ngIf="isRAMConfigurationAbsent()" format="n" formControlName="lenghtOffset">
    </kendo-numerictextbox>
    <kendo-dropdownlist *ngIf="!isRAMConfigurationAbsent()" formControlName="lenghtOffset" [valuePrimitive]="true"
      [data]="selectedMachine?.pressbedRamConfigurations" [textField]="'position'" [valueField]="'position'">
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        #{{ dataItem?.index }} - {{dataItem.position}}
      </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror *ngIf="gluingPlanForm.controls.lenghtOffset.errors?.exeedLenght" translate
      [translateParams]="{maxLength: selectedMachine?.maxPressLength}">GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH</kendo-formerror>
  </kendo-formfield>
  <kendo-formfield *ngIf="data?.isStaticalPressGroup">
    <kendo-label text="{{ 'AoT.Lenght' | translate }}"></kendo-label>
    <kendo-numerictextbox [readonly]="true" format="n" formControlName="length"></kendo-numerictextbox>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label text="{{ 'GluePlan.PlannedExecutionStartDate' | translate }}"></kendo-label>
    <kendo-datetimepicker formControlName="plannedExecutionStartDate"></kendo-datetimepicker>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label text="{{ 'GluePlan.PlannedExecutionEndDate' | translate }}"></kendo-label>
    <kendo-datetimepicker formControlName="plannedExecutionEndDate"
      (valueChange)="onEndDateTimeChange()"></kendo-datetimepicker>
  </kendo-formfield>
  <kendo-formfield *ngIf="data?.isContinuousPressGroup">
    <kendo-label translate>GluePlan.Dimension</kendo-label>
    <kendo-dropdownlist formControlName="beamLaminaDimentionId" [valuePrimitive]="true" [data]="dimensions"
      [textField]="'width'" [readonly]="!this.data.isNew" [valueField]="'beamLamminaDimensionID'">
    </kendo-dropdownlist>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label text="{{ 'GluePlan.SelectState' | translate }}"></kendo-label>
    <kendo-dropdownlist formControlName="glueSetStateId" [valuePrimitive]="true" [data]="states" [textField]="'name'"
      [valueField]="'glueSetStateId'">
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        {{("GluePlan." + dataItem.name.toUpperCase()) | translate}}
      </ng-template>
    </kendo-dropdownlist>
  </kendo-formfield>
</form>
