<h1 translate>Operative.Machines</h1>

<section class="app-button-row">
  <div style="flex-grow: 1;"></div>
  <button id="add-new" kendoButton themeColor="primary" class="k-mb-1" (click)="addMachineRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>
<kendo-grid #machineEditorGrid
  [kendoGridBinding]="machines"
  class="crud-grid"
  [pageSize]="state.take"
  [skip]="state.skip"
  [pageable]="true"
  [resizable]="true"
  [size]="'small'"
  (edit)="editHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)">

  <kendo-grid-column
    field="name"
    title="{{ 'App.Name' | translate }}">
    <ng-template
      kendoGridEditTemplate
      let-formGroup="formGroup">
      <kendo-textbox id="name" [formControl]="formGroup.get('name')"></kendo-textbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="machineGroupId"
    title="{{ 'Operative.MachineGroup' | translate }}"
    [editable]="true">
    <ng-template
      kendoGridCellTemplate
      let-dataItem>
      {{getMgName(dataItem.machineGroupId)}}
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-formGroup="formGroup">
      <kendo-combobox
        id="machine-groupid"
        [data]="machinesGroups"
        textField="name"
        valueField="machineGroupId"
        [formControl]="formGroup.get('machineGroupId')"
        [valuePrimitive]="true">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="colorCode"
    title="{{ 'Operative.ColorCode' | translate }}"
    [editable]="true">
    <ng-template
      kendoGridCellTemplate
      let-dataItem>
      <div [ngStyle]="{'background-color': dataItem.colorCode}" class="machineColor">
        <br/>
      </div>
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-formGroup="formGroup">
      <kendo-colorpicker
        id="color-code"
        view="gradient"
        [formControl]="formGroup.get('colorCode')">
      </kendo-colorpicker>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="{{ 'Operative.isPlannedWidthEditable' | translate }}">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <input id="is-planned-width-editable" class="k-checkbox group-check" (click)="togglePlannedWidthCheckbox()"
        [checked]="dataItem.isPlannedWidthEditable" type="checkbox" kendoCheckBox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input class="k-checkbox group-check" [checked]="dataItem.isPlannedWidthEditable" type="checkbox"
        [disabled]="true" kendoCheckBox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="{{ 'Operative.isPlannedThicknessEditable' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input class="k-checkbox group-check" [checked]="dataItem.isPlannedThicknessEditable" type="checkbox"
        [disabled]="true" kendoCheckBox>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <input id="is-planned-thickness-editable" class="k-checkbox group-check"
        (click)="togglePlannedThicknessCheckbox()" [checked]="dataItem.isPlannedThicknessEditable" type="checkbox"
        kendoCheckBox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="{{ 'Operative.isPlannedLengthEditable' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input class="k-checkbox group-check" [checked]="dataItem.isPlannedLengthEditable" type="checkbox"
        [disabled]="true" kendoCheckBox>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <input id="is-planned-length-editable" class="k-checkbox group-check" (click)="togglePlannedLengthCheckbox()"
        [checked]="dataItem.isPlannedLengthEditable" type="checkbox" kendoCheckBox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="{{ 'Operative.isPlannedQualityEditable' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input class="k-checkbox group-check" [checked]="dataItem.isPlannedQualityEditable" type="checkbox"
        [disabled]="true" kendoCheckBox>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <input id="is-planned-quality-editable" class="k-checkbox group-check" (click)="togglePlannedQualityCheckbox()"
        [checked]="dataItem.isPlannedQualityEditable" type="checkbox" kendoCheckBox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="{{ 'Operative.isSpeciesEditable' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input class="k-checkbox group-check" [checked]="dataItem.isSpeciesEditable" type="checkbox" [disabled]="true"
        kendoCheckBox>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <input id="is-species-editable" class="k-checkbox group-check" (click)="toggleSpeciesCheckbox()"
        [checked]="dataItem.isSpeciesEditable" type="checkbox" kendoCheckBox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="desiredNumberOfLayers" title="{{ 'Operative.DesiredNumberOfLayers' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="desired-number-of-layers" [formControl]="formGroup.get('desiredNumberOfLayers')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="minPressLength" title="{{ 'Operative.MinPressLength' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="min-press-length" [formControl]="formGroup.get('minPressLength')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="maxPressLength" title="{{ 'Operative.MaxPressLength' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="max-press-length" [formControl]="formGroup.get('maxPressLength')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="minDesiredPressLength" title="{{ 'Operative.MinDesiredPressLength' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="min-desired-press-length" [formControl]="formGroup.get('minDesiredPressLength')"
        format="d" [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="maxDesiredPressLength" title="{{ 'Operative.MaxDesiredPressLength' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="max-desired-press-length" [formControl]="formGroup.get('maxDesiredPressLength')"
        format="d" [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="defaultCutOffLength" title="{{ 'Operative.DefaultCutOffLength' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="default-cut-off-length" [formControl]="formGroup.get('defaultCutOffLength')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="cutWidth" title="{{ 'Operative.CutWidth' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="cut-width" [formControl]="formGroup.get('cutWidth')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="testPieceLength" title="{{ 'Operative.TestPieceLength' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="test-piece-length" [formControl]="formGroup.get('testPieceLength')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="minPressHight" title="{{ 'Operative.MinPressHight' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="min-press-hight" [formControl]="formGroup.get('minPressHight')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="maxPressHight" title="{{ 'Operative.MaxPressHight' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="max-press-hight" [formControl]="formGroup.get('maxPressHight')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="minDesiredPressHight" title="{{ 'Operative.MinDesiredPressHight' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="min-desired-press-hight" [formControl]="formGroup.get('minDesiredPressHight')"
        format="d" [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="maxDesiredPressHight" title="{{ 'Operative.MaxDesiredPressHight' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="max-desired-press-hight" [formControl]="formGroup.get('maxDesiredPressHight')"
        format="d" [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="minPressWidth" title="{{ 'Operative.MinPressWidth' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="min-press-width" [formControl]="formGroup.get('minPressWidth')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="maxPressWidth" title="{{ 'Operative.MaxPressWidth' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="max-press-width" [formControl]="formGroup.get('maxPressWidth')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="{{ 'Machine.RamConfiguration'| translate  }}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button id="add-ram-configuration" kendoButton themeColor="primary" (click)="addRamConfig(dataItem)">
        {{ (dataItem.pressbedRamConfigurations?.length > 0 ? 'App.View' : 'App.Add') | translate }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="maxLamellaBufferBox" title="{{ 'Operative.MaxLamellaBufferBox' | translate }}">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-numerictextbox id="max-press-width" [formControl]="formGroup.get('maxLamellaBufferBox')" format="d"
        [min]="0"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}" [width]="180">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button id="edit" kendoGridEditCommand [primary]="true">
        {{ 'App.Edit' | translate }}
      </button>
      <button id="remove" kendoGridRemoveCommand themeColor="error">
        {{ 'App.Remove' | translate}}
      </button>
      <button id="save" kendoGridSaveCommand themeColor="primary" [disabled]="formGroup?.invalid">
        {{ (isNew ? "App.Add" : "App.Update") | translate }}
      </button>
      <button id="cancel" themeColor="error" kendoGridCancelCommand>
        {{ 'App.Cancel' | translate }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
