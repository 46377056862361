<div class="planner-container">
  <div [ngClass]="{ 'major_left': isLeftMajor, 'minor_left': !isLeftMajor, 'left-section' : true}">
    <app-order-view [selectedLamminaDimension]="selectedLamminaDim" [uiQty]="uiQty" [isLeftMajor]="isLeftMajor"
      [selectedPlan]="selectedPlan" (optimise)="openOptimizationEditor()" (updateSize)="resizeGrid($event)"
      [filterOnSelectGluePlan]="false" [siteId]="selectedMachine?.siteId" [isRefreshGrid]="isRefreshOrderView">
    </app-order-view>
  </div>
  <div [ngClass]="{ 'minor_right': isLeftMajor, 'major_right': !isLeftMajor}">
    <div class="row k-d-flex mb-1">
      <app-machine-selector-dropdown [ngModel]="selectedMachineId" [isStaticalPressGroup]="true"
        (selectedMachine)="onMachineSelected($event)">
      </app-machine-selector-dropdown>
    </div>
    <div class="row mb-1 k-d-flex">
      <div class="pressbed" #pressbedView>
        <app-pressbed-planning-view [machine]="selectedMachine" [gluPlans]="gluPlans"
          [refreshPressbedView]="refreshPressbedView.asObservable()" (relseGlueplans)="relseGlueplans($event)">
        </app-pressbed-planning-view>
      </div>
    </div>
    <div class="k-d-flex">
      <button kendoButton (click)="addGluingPlan(null)" themeColor="primary">
        {{'GluePlan.AddGluingPlan' | translate}}
      </button>
      <button class="ml-5" kendoButton (click)="saveAllGluingPlans()"
        [disabled]="(gluelamStaticalMediatorService.isGlueplanSaveAllChangesValid | async) == false"
        themeColor="primary">
        {{'GluePlan.SaveAllGluingPlan' | translate}}
      </button>
    </div>
    <div class="glue-set-container">
      <div class="k-d-flex k-h-full" *ngFor="let plan of gluPlans;">
        <div class="k-d-flex k-h-full"
          [ngClass]="{'selected-glue-plan': plan.gluingPlanID === this.selectedPlan?.gluingPlanID, 'pointer' : true }">
          <app-glue-plan-view-statical class="k-d-flex k-h-full" [id]="plan.gluingPlanID" [machine]="selectedMachine"
            (onUpdateSelection)="updateSelection($event)" (onDeleteGluePlan)="deleteGluingPlan($event)"
            (onEditGluePlan)="editGluingPlanBlockProduction(plan)" (onCalculateUnits)="onCalcTotalsPerBeamOrderRow($event)"
            [dimension]="selectedLamminaDim" [plan]="plan">
          </app-glue-plan-view-statical>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirmation-dialog *ngIf="release.itemToReleased" [msg]="'GluePlan.GluePlanConfirmReleased'"
  (conformationDialogCloseEvent)="confirmGlueplansRelease($event)">
</app-confirmation-dialog>
