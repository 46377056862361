import { MbscModule } from '@mobiscroll/angular';
import 'hammerjs';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppMissingTranslationHandler } from './shared/helpers/app-missing-translation-handler';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { HomeComponent } from './core/components/home/home.component';
import { KendoImportModule } from './core/kendo-import.module';
import { LoginRedirectComponent } from './core/components/login-redirect/login-redirect.component';
import { MaterialImportModule } from './core/material-import.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PageNotFoundComponent } from '@app/core/components/page-not-found/page-not-found.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeAT from '@angular/common/locales/de-AT';
import localeHI from '@angular/common/locales/hi';
import localeSE from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
import { CoreModule } from './core/core.module';
import { NotificationSnackComponent } from './core/components/notification-snack/notification-snack.component';
import { UserSettingsComponent } from './core/components/user-settings/user-settings.component';
import { EntityAdminModule } from './modules/entity-admin/entity-admin.module';
import { ModelModule } from './modules/model/model.module';
import { OperativeModule } from './modules/operative/operative.module';
import { OptimizationModule } from './modules/optimization/optimization.module';
import { ConfigurationService } from './core/services/custom-services/configuration.service';
import { AuthService } from './core/services/auth-service/auth.service';
import { MenuService } from './core/services/http-services/common/menu.service';
import { VmService } from './core/services/http-services/common/vm.service';
import { AppNotificationService } from './core/services/custom-services/notification.service';
import { EntityService } from './core/services/http-services/model/entity.service';
import { EntityPropertyService } from './core/services/http-services/model/entity-property.service';
import { BeamMaterialLinesService } from './core/services/http-services/gluelam/beam-material-lines.service';
import { ApplicationInsightsService } from './core/services/custom-services/application-insights.service';
import { UnitService } from './core/services/http-services/common/unit-service';
import { MachineService } from './core/services/http-services/operative/machine.service';
import { MachineGroupService } from './core/services/http-services/operative/machine-group.service';
import { EntityGroupService } from './core/services/custom-services/entity-group.service';
import { ToastrModule } from 'ngx-toastr';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { UserProfileComponent } from './modules/app-controls/user-profile/user-profile.component';
import { AppControlsModule } from './modules/app-controls/app-controls.module';
import { GluelamModule } from './modules/gluelam/gluelam.module';
import { RamConfigurationService } from './core/services/http-services/operative/ram-configuration.service';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { EntitySimpleService } from './core/services/http-services/model/entity-simple.service';

registerLocaleData(localeSE, 'sv-SE');
registerLocaleData(localeAT, 'de-AT');
registerLocaleData(localeHI, 'hi-IN');

@Injectable({ providedIn: 'root' })
export class HttpClientTrans extends HttpClient {
  constructor(handler: HttpBackend) {
    super(handler);
  }
}

export function HttpLoaderFactory(http: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(http));
}

@NgModule({
  declarations: [
    AppComponent,
    UserProfileComponent,
    UserSettingsComponent,
    PageNotFoundComponent,
    NotificationSnackComponent,
    LoginRedirectComponent,
    HomeComponent
  ],
  imports: [
    MbscModule,
    SharedModule,
    BrowserModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    HttpClientModule,
    MaterialImportModule,
    KendoImportModule,
    GluelamModule,
    EntityAdminModule,
    OperativeModule,
    DashboardModule,
    ModelModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: AppMissingTranslationHandler },
      useDefaultLang: true
    }),
    MsalModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    AppControlsModule,
    NotificationModule,
    OptimizationModule,
    CoreModule,
    NgxJsonViewerModule
  ],
  providers: [
    ConfigurationService,
    AuthService,
    MenuService,
    VmService,
    AppNotificationService,
    Title,
    EntityService,
    EntitySimpleService,
    EntityPropertyService,
    BeamMaterialLinesService,
    ApplicationInsightsService,
    UnitService,
    MachineService,
    MachineGroupService,
    EntityGroupService,
    RamConfigurationService
  ],
  exports: [],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
