<kendo-card *ngIf="bmgs.layers.length > 0" width="100%">
  <kendo-card-header  style="display: flex; flex-direction: column;">
    <div class="row">
      <h4
          kendoCardTitle>
          {{ 'GlueSet.Glueing' | translate:{no: bmgs.beamMaterialGlueSetID > 0 ? bmgs.beamMaterialGlueSetID : 'NEW'} }} {{getStateText(bmgs.beamMaterialGlueSetState.glueSetStateId) | translate | uppercase }}
      </h4>
      <div style="flex-grow: 1;"></div>
        <kendo-svg-icon themeColor="success" size="large" [icon]="checkCircleIcon" *ngIf="!bmgs.save">
        </kendo-svg-icon>
        <kendo-svg-icon themeColor="warning" size="large" [icon]="pencilIcon" *ngIf="bmgs.save && !bmgs.errorMessageText">
        </kendo-svg-icon>
        <div kendoTooltip>
        <kendo-svg-icon themeColor="error" size="large" [icon]="warningCircleIcon" [title]="getTooltip(bmgs)" *ngIf="isAnyErrorOrWarning(bmgs)">
        </kendo-svg-icon>
        </div>
        <div>
          <kendo-dropdownbutton
          [data]="rowActions"
          themeColor="primary"
          [svgIcon]="menuIcon">
            <ng-template kendoDropDownButtonItemTemplate let-dataItem>
              <kendo-svg-icon [icon]="dataItem.svgIcon"></kendo-svg-icon> {{ dataItem.name | translate }}
            </ng-template>
          </kendo-dropdownbutton>
        </div>
    </div>
    <div class="row">
      <h5 kendoCardSubtitle
          translate
          [translateParams]="{lenght: bmgs.length }"
          >GlueSet.Length
      </h5>
      <div style="flex-grow: 1;"></div>
      <div class="row">
        <div class="info-pill info-pill-accsent" *ngIf="bmgs.glusetBatch > 0">
          {{ "GlueSet.Batch" | translate }}: {{bmgs.glusetBatch}}
        </div>
        <div class="info-pill info-pill-accsent" *ngIf="bmgs.Template">
          {{ "GlueSet.Template" | translate }}: {{ bmgs.Template.BeamMaterialTypeID }}
        </div>
      </div>
    </div>
  </kendo-card-header>
  <kendo-card-body
    style="display: flex; flex-direction: column;">

    <div style="display: flex; flex-direction: row;">
      <svg [attr.width]="ch.getHight(widthSideMM)" [attr.height]="bmgs.layers.length * hightSideLayerPX"
            style="margin-right: 3px;">
        <rect [attr.width]="ch.getHight(widthSideMM)" height="100%" style="fill: white; stroke-width: 1; stroke: rgb(0, 0, 0);"></rect>

        <svg *ngFor="let layer of bmgs.layers; let i = index" [attr.width]="ch.getHight(widthSideMM)">
          <rect *ngFor="let bm of layer.BeamMaterials; let ib = index" [attr.x]="calcBMSideX(layer, ib)"
                [attr.y]="i * hightSideLayerPX" [attr.width]="ch.getHight(bm.getHight())"
                [attr.height]="hightSideLayerPX" [ngStyle]="bm.getColor()"></rect>
          <text *ngFor="let bm of layer.BeamMaterials; let ib = index" [attr.x]="calcBMSideX(layer, ib) + 5"
                [attr.y]="i * hightSideLayerPX + 14" [attr.width]="ch.getHight(bm.getHight())"
                [attr.height]="hightSideLayerPX">
            {{ bm.numberOfLamminas }}
          </text>
        </svg>
      </svg>
      <div style="display: flex; flex-direction: column;">
        <div
          *ngFor="let l of bmgs.layers"
          style="height: 25px;">
          <key-value-display
            [lable]="'H'"
            [value]="getHeightAllocation(l.totalHeight)"
            [useBackground]="false">
          </key-value-display>
        </div>
      </div>
      <div style="flex-grow: 1;"></div>
      <kendo-formfield
        *ngIf="editable"
        class="w-50px">
        <kendo-textbox
          placeholder="{{ 'GlueSet.Performed_Times' | translate }}"
          [ngModel]="bmgs.performedTimes"
          (ngModelChange)="onRepeatsChanged($event)"></kendo-textbox>
      </kendo-formfield>
    </div>
    <div #bmgsContainer>
      <svg [attr.height]="containerHight" [attr.width]="gsWidth" #layer
              *ngFor="let layer of bmgs.layers; let i_layer = index" [attr.y]="calcY(i_layer)" X="0">
        <rect x="0" y="0" [attr.width]="gsWidth" [attr.height]="containerHight"
                style="fill: white; stroke-width: 1; stroke: rgb(0, 0, 0);"
                (contextmenu)="openBMGSContextMenu(layer, $event)">
        </rect>
        <svg app-beam-material *ngFor="let bm of layer.BeamMaterials; let i = index"
                [bm]="bm" [ch]="ch" [attr.y]="calcYForLayer(i, layer)" [isSplitView]="false"
                (openBeamMaterialLineMenu)="openContextMenu($event)"></svg>
      </svg>
    </div>

  </kendo-card-body>
</kendo-card>
