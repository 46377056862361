import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {  UntypedFormArray, Validators, AbstractControl, FormGroup, FormArray, FormControl } from '@angular/forms';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { RunJobService } from '@app/core/services/http-services/run-job/run-job.service';
import { IMachineGroupDTO, MachineGroupService } from '@app/core/services/http-services/operative/machine-group.service';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { IAutoSchedulerStartDTO, IAutoSchedulerMgDTO } from '@app/core/models/autoSchedulerStartDTO';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { StepType } from '@progress/kendo-angular-layout';
import { Observable, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { WatsonStatus, WatsonRunStatus, WatsonSolveStatus } from '@app/core/models/watson-status';
import { AdminService } from '@app/core/services/http-services/admin/admin.service';
import { WatsonDataSetEnum } from '@app/core/models/watson-dataset.enum';
import { WatsonDeployedModel } from '@app/core/models/watson-deployed.model';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { AutoSchedulerForm } from '@app/core/models/forms/production-orders/auto-scheduler-input-popup/auto-scheduler-form.model';
import { AutoSchedulerMachineGroupForm } from '@app/core/models/forms/production-orders/auto-scheduler-input-popup/auto-scheduler-machine-group-form';

marker('Operative.AutoSchedulerInProgress');
marker('Operative.AutoSchedulerSuccess');


export const oneIsSelected = () => {
  return (c: AbstractControl): { [key: string]: any } => {
    const array = c as UntypedFormArray;

    if (array.value.filter(mg => mg.isSelected === true).length > 0) {
      return null;
    }

    return { MinLengthArray: true };
  };
};

const createFormGroup = (machineGroups: IMachineGroupDTO[], machineGroupsIds) => new FormGroup<AutoSchedulerForm>({
  machineGroups: new FormArray<FormGroup>(
    machineGroups.map<FormGroup>(mg => createMachineGroupFormGroup(mg, machineGroupsIds)),oneIsSelected()
  ),
  timeOrigin: new FormControl<Date>(new Date(Date.now()), [Validators.required]),
  timeZoneOffset: new FormControl<number>(
    new Date(Date.now()).getTimezoneOffset(),
    [Validators.required, Validators.pattern(RegexHelper.positiveNegativeWithDotComma)]
  ),
  deployedModelId: new FormControl<string | null>(null, [Validators.required]),
  noOfDays: new FormControl<number>(60, [Validators.required])
});

const isMachineGroupChecked = (machineGroupId, isDisabledForAutoScheduler, machineGroupsIds) => {
  if (isDisabledForAutoScheduler) {
    return false;
  }

  if (machineGroupsIds != null && machineGroupsIds !== undefined && machineGroupsIds.includes(machineGroupId)) {
    return true;
  }

  return false;
};

const createMachineGroupFormGroup = (mg: IMachineGroupDTO, machineGroupsIds: any[]): FormGroup<AutoSchedulerMachineGroupForm> => {
  return new FormGroup<AutoSchedulerMachineGroupForm>({
    name: new FormControl<string>(mg.name),
    machineGroupId: new FormControl<number>(mg.machineGroupId),
    isDisabledForAutoSheduler: new FormControl<boolean>(mg.isDisabledForAutoSheduler),
    preserveOrder: new FormControl<boolean>(mg.preserveOrderAutoSheduler),
    isSelected: new FormControl<boolean>(
      isMachineGroupChecked(mg.machineGroupId, mg.isDisabledForAutoSheduler, machineGroupsIds)
    )
  });
};

@Component({
  selector: 'app-auto-scheduler-input-popup',
  templateUrl: './auto-scheduler-input-popup.component.html',
  styleUrls: ['./auto-scheduler-input-popup.component.css']
})
export class AutoSchedulerInputPopupComponent extends DialogContentBase implements OnInit, OnDestroy {
  // Stepper region
  stepType: StepType = 'full';
  current = 0;

  paramStepLable = 'Set Params';
  createStepLable = 'Crate Job';
  optiStepLable = 'Run Optimization';
  resultStepLable = 'Get Result';
  noResStepLable = 'No Res';
  resultProcessedLable = 'Result Processed';
  jobState: WatsonStatus;
  watsonJobId: string;
  autoSchedulerForm: FormGroup;
  _machineGroupsData: IMachineGroupDTO[];
  disableSubmit: boolean = false;
  watsonDeployedModels: WatsonDeployedModel[] = [];

  private destroy$ = new Subject<void>();

  get getMachineGroups() {
    return this.autoSchedulerForm.get('machineGroups') as UntypedFormArray;
  };

  buildForm() {
    const selectedMachineGroupIds = JSON.parse(this.localStorageService.getItem(LocalStorageService.selectedMachineGroups)) as number[];
    this.autoSchedulerForm = createFormGroup(this._machineGroupsData, selectedMachineGroupIds);
  }

  shouldValidate = (): boolean => {
    return this.current === 3;
  };

  optiSteps = [
    { label: this.paramStepLable, icon: '', isValid: true, time: 10, state: null, solveStatus: null },
    { label: this.createStepLable, icon: '', isValid: true },
    { label: this.optiStepLable, isValid: true },
    { label: this.noResStepLable, isValid: true, validate: this.shouldValidate, optional: true },
    { label: this.resultStepLable, isValid: true },
    { label: this.resultProcessedLable, isValid: true }
  ];

  private pollCounter: Observable<number>;
  private counterSub: Subscription;

  constructor(
   @Inject(DialogRef) public data: {},
    private readonly adminService: AdminService,
    private readonly notificationService: AppNotificationService,
    public readonly runJobService: RunJobService,
    public readonly machineGroupService: MachineGroupService,
    public readonly localStorageService: LocalStorageService,
    private dialogRef: DialogRef) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.machineGroupService.query()
    .pipe(takeUntil(this.destroy$))
    .subscribe(ret => {
      this._machineGroupsData = ret.data as IMachineGroupDTO[];
      if (this._machineGroupsData != null && this._machineGroupsData !== undefined) {
        this.buildForm();
      }
    });
    this.getDeployedModels();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  override ngAfterViewInit(): void {
    this.pollCounter = timer(0, 3000); // polling
  }

  onSubmit() {
    const formValue = this.autoSchedulerForm.value;
    const groups: any[] = formValue.machineGroups;

    this.disableSubmit = true;
    this.notificationService.notifyInfoAppChanel('Operative.AutoSchedulerInProgress');

    this.runJobService.passInputToAutoAcheduler(<IAutoSchedulerStartDTO>{
      groups: groups.filter(mg => mg.isSelected === true).map<IAutoSchedulerMgDTO>(mg => {
        return <IAutoSchedulerMgDTO>{
          mashineGroupId: mg.machineGroupId,
          perserveOrder: mg.preserveOrder === true ?? false
        };
      }),
      timeOrigin: formValue.timeOrigin,
      timeZoneOffset: formValue.timeZoneOffset,
      deployedModelId: formValue.deployedModelId,
      days: formValue.noOfDays
    })
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (ret) => {
        this.disableSubmit = false;
        this.watsonJobId = ret.jobId;
        this.jobState = ret;
        this.StartJobPole();
        this.PollState();
      },
      () => {
        this.disableSubmit = false;
      }
    );
  }

  StartJobPole() {
    this.counterSub = this.pollCounter.subscribe(() => {
      if (this.watsonJobId) {
        this.PollState();
      }
    });
  }

  close() {
    this.dialogRef.close(true);
  }

  private PollState() {
    this.runJobService.PollState(this.watsonJobId, true, WatsonDataSetEnum.AutoSheduler.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe(d => {
        const [s, state] = d;
        this.jobState = s;
        console.warn(s.status);
        switch (state) {
          case -1:
            break;
          case 1:
            this.current = 1;
            break;
          case 2:
            this.current = 2;
            break;
          case 3:
            this.StopCounters();
            this.current = 3;
            this.optiSteps[3].isValid = false;
            this.optiSteps[3].state = s.status + '_' + s.solveStatus;
            break;
          case 4:
            this.current = 4;
            this.optiSteps[4].state = s.status + '_' + s.solveStatus;
            break;
          case 5:
            this.current = 3;
            this.optiSteps[3].state = s.status + '_' + s.solveStatus;
            this.StopCounters();
            break;
          case 6:
            this.current = 5;
            this.optiSteps[5].state = s.status + '_' + s.solveStatus;
            this.StopCounters();
            this.afterSuccess();
            break;
        }
      }, () => {
        this.StopCounters();
        this.current = 3;
        this.jobState.status = WatsonRunStatus.appError;
        this.jobState.solveStatus = WatsonSolveStatus.appError;
      });
  }

  private StopCounters() {
    this.counterSub.unsubscribe();
    this.disableSubmit = false;
  }

  private afterSuccess() {
    setTimeout(() => {
      if (this.current === 5) {
        this.notificationService.notifySucsessAppChanel('Operative.AutoSchedulerSuccess');
        this.close();
      }
    }, 3000);
  }

  private getDeployedModels() {
    this.adminService.getWatsonDeployments(WatsonDataSetEnum.AutoSheduler)
      .subscribe(d => {
        this.watsonDeployedModels = d;
        this.autoSchedulerForm.get('deployedModelId').setValue(this.watsonDeployedModels[0].Id);
      });
  }
}
